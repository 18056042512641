<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт арычной сети</h3>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties[0] }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Расположение</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="location"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Марка арыка</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
              :items="passport_ditchbrand"
              v-model="brand_id"
              item-text="name"
              item-value="id"
              placeholder="Марка арыка"
              outlined
              dense
              hide-details
              class="mb-1"
              clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Направление</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="direction"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Начало</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="beginning"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Конец</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="ending"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип лотка</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
              :items="passport_traytype"
              v-model="tray_type_id"
              item-text="name"
              item-value="id"
              placeholder="Тип лотка"
              outlined
              dense
              hide-details
              class="mb-1"
              clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Протяженность (км)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="length"
              type="number"
              step="0.001"
              min="0"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Местоположение</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
              :items="passport_locationtype"
              v-model="location_type_id"
              item-text="name"
              item-value="id"
              placeholder="Местоположение"
              outlined
              dense
              hide-details
              class="mb-1"
              clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Сторона дороги</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="road_side"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="16">
          <v-select
              :items="passport_serviceorganization"
              v-model="serviceorganizations"
              item-text="name"
              item-value="id"
              placeholder="Обслуживающая организация"
              outlined
              dense
              hide-details
              class="mb-1"
              clearable
              multiple
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. районы</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
        </v-col>
      </v-row>

      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn type="button" class="font-weight-bold" @click="saveClick"
              >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col v-if="isDraw" cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn type="button" class="font-weight-bold" @click="clearData"
            >Очистить</v-btn
            ></v-card-text
            >
          </v-col>
          <v-col v-else cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
                type="button"
                class="font-weight-bold"
                @click="deleteClick"
            >Удалить</v-btn
            ></v-card-text
            >
          </v-col>
        </v-row>
      </div>

      <v-row dense>
        <v-col>
          <v-btn
              color="warning"
              style="color: black"
              @click="$emit('open-appeal')"
          >Сообщить об ошибке</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "DitchnetworkPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_serviceorganization: [],
      passport_ditchbrand: [],
      passport_traytype: [],
      passport_locationtype: [],

      location: null,
      brand_id: null,
      direction: null,
      beginning: null,
      ending: null,
      tray_type_id: null,
      length: null,
      location_type_id: null,
      road_side: null,
      ditchbrands: null,
      serviceorganizations: [],
    };
  },

  methods: {
    saveClick() {
      const data = {
        location: this.location,
        brand_id: this.brand_id,
        direction: this.direction,
        beginning: this.beginning,
        ending: this.ending,
        tray_type_id: this.tray_type_id,
        length: this.length,
        location_type_id: this.location_type_id,
        road_side: this.road_side,
        ditchbrands: this.ditchbrands,
        serviceorganizations: this.serviceorganizations
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.location = null;
      this.brand_id = null;
      this.direction = null;
      this.beginning = null;
      this.ending = null;
      this.tray_type_id = null;
      this.length = null;
      this.location_type_id = null;
      this.road_side = null;
      this.ditchbrands = null;
      this.serviceorganizations = [];
    },

  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}serviceorganization`);
    this.passport_serviceorganization = response.data;
    let response2 = await Vue.axios.get(`${URL_API}ditchbrand`);
    this.passport_ditchbrand = response2.data;
    let response3 = await Vue.axios.get(`${URL_API}traytype`);
    this.passport_traytype = response3.data;
    let response4 = await Vue.axios.get(`${URL_API}locationtype`);
    this.passport_locationtype = response4.data;

    if (this.properties && this.properties[0]) {
      response = await Vue.axios.get(
          `${URL_API}ditchnetwork/view/${this.properties[0]}`
      );
      const data = response.data;
      console.log("данные воды", data);
      this.location = data.location;
      this.brand_id = data.brand_id;
      this.direction = data.direction;
      this.beginning = data.beginning;
      this.ending = data.ending;
      this.tray_type_id = data.tray_type_id;
      this.length = data.length;
      this.location_type_id = data.location_type_id;
      this.road_side = data.road_side;
      this.ditchbrands = data.ditchbrands;
      this.serviceorganizations = data.serviceorganizations;
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
