<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт фонтана</h3>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Имя объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="name"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адрес объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="address"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Год постройки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu v-model="plantingDateMenu">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="construction_year"
                v-bind="attrs"
                v-on="on"
                hide-details
                disabled
              ></v-text-field>
            </template>
            <v-date-picker-years min="1960" max="2024" v-model="construction_year" scrollable></v-date-picker-years>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип фонтана</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_fontaintype"
            v-model="type_id"
            item-text="name"
            item-value="id"
            placeholder="Тип фонтана"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип чаши</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_bowltype"
            v-model="bowl_id"
            item-text="name"
            item-value="id"
            placeholder="Тип чаши"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Общее количество чаш</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="bowl_number"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Площадь (М2)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="area"
            type="number"
            step="0.01"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Объем (М3)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="volume"
            type="number"
            step="0.01"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Подводные светильники</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="supply_lamps"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип форсунки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_injectortype"
            v-model="injectortypes"
            item-text="name"
            item-value="id"
            placeholder="Тип форсунки"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
            multiple
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Количество форсунок</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="injectors"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="4">
          <v-card-text class="text-start">Состояние</v-card-text>
        </v-col>
        <v-col cols="12" sm="8">
          <v-select
            :items="passport_fountaincondition"
            v-model="condition_id"
            item-text="name"
            item-value="id"
            placeholder="Состояние"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Погружные насосы</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="submersible_pumps"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Центробежные насосы</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="centrifugal_pumps"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Прожекторы</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="spotlights"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Видеокамеры</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="video_cameras"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">RGB подсветка</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="rgb_backlight"
            type="number"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Электричество</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="electricity"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Водоснабжение</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_watersupply"
            v-model="water_supply_id"
            item-text="name"
            item-value="id"
            placeholder="Водоснабжение"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Номер кадастра</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="cadastre_number"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">График уборки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_cleaningschedule"
            v-model="cleaning_schedule_id"
            item-text="name"
            item-value="id"
            placeholder="График уборки"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Примечание</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="note"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="8">
          <v-card-text class="text-start">Административный район</v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
        </v-col>
      </v-row>

      <v-row
          dense
          v-if="imageUrl"
          style="max-height: 100px; max-width: 100px;"
      >
        <v-col cols="12" sm="11" class="pa-0">
          <expandable-image class="image" :src="imageUrl"></expandable-image>
        </v-col>
        <v-col cols="12" sm="1" class="pa-0">
          <v-btn icon @click="clearImg">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn
              color="warning"
              style="color: black"
              @click="$emit('open-appeal')"
          >Сообщить об ошибке</v-btn
          >
        </v-col>
      </v-row>

    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL, URL_API } from "@/settings/apiConnect";

export default {
  name: "FountainPassport",

  props: {
    saveFountain: Function,
    deleteFountain: Function,
    properties: Object,
    isDraw: Boolean
  },

  watch: {
    properties(newId, oldId) {
      if (newId.id !== oldId.id) {
        this.getPassportData();
      }
    }
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_fontaintype: [],
      passport_bowltype: [],
      passport_injectortype: [],
      passport_fountaincondition: [],
      passport_watersupply: [],
      passport_cleaningschedule: [],

      image: undefined,
      imageUrl: "",
      imgRules: [
        value =>
          !value ||
          value.size < 100000000 ||
          "Размер фото не должен превышать 100 МБ!"
      ],

      name: null,
      address: null,
      construction_year: null,
      type_id: null,
      bowl_id: null,
      bowl_number: null,
      area: null,
      volume: null,
      supply_lamps: null,
      injectors: null,
      condition_id: null,
      submersible_pumps: null,
      centrifugal_pumps: null,
      spotlights: null,
      video_cameras: null,
      rgb_backlight: null,
      electricity: null,
      water_supply_id: null,
      cadastre_number: null,
      cleaning_schedule_id: null,
      note: null,
      district_id: null,
      injectortypes: [],
      fountaintype: null,
      bowltype: null,
      fountaincondition: null,
      watersupply: null,
      cleaningschedule: null,
      district: null,
      images: [],
      documents: [],
    };
  },

  methods: {
    saveClick() {
      const fountainData = {
        name: this.name,
        address: this.address,
        construction_year: this.construction_year,
        type_id: this.type_id,
        bowl_id: this.bowl_id,
        bowl_number: this.bowl_number,
        area: this.area,
        volume: this.volume,
        supply_lamps: this.supply_lamps,
        injectors: this.injectors,
        condition_id: this.condition_id,
        submersible_pumps: this.submersible_pumps,
        centrifugal_pumps: this.centrifugal_pumps,
        spotlights: this.spotlights,
        video_cameras: this.video_cameras,
        rgb_backlight: this.rgb_backlight,
        electricity: this.electricity,
        water_supply_id: this.water_supply_id,
        cadastre_number: this.cadastre_number,
        cleaning_schedule_id: this.cleaning_schedule_id,
        note: this.note,
        injectortypes: this.injectortypes,
        fountaintype: this.fountaintype,
        bowltype: this.bowltype,
        fountaincondition: this.fountaincondition,
        watersupply: this.watersupply,
        cleaningschedule: this.cleaningschedule,
      };

      this.saveFountain(fountainData);
    },
    deleteClick() {
      this.deleteFountain();
    },
    clearData() {
      this.name = null;
      this.address = null;
      this.construction_year = null;
      this.type_id = null;
      this.bowl_id = null;
      this.bowl_number = null;
      this.area = null;
      this.volume = null;
      this.supply_lamps = null;
      this.injectors = null;
      this.condition_id = null;
      this.submersible_pumps = null;
      this.centrifugal_pumps = null;
      this.spotlights = null;
      this.video_cameras = null;
      this.rgb_backlight = null;
      this.electricity = null;
      this.water_supply_id = null;
      this.cadastre_number = null;
      this.cleaning_schedule_id = null;
      this.note = null;
      this.district_id = null;
      this.injectortypes = [];
      this.fountaintype = null;
      this.bowltype = null;
      this.fountaincondition = null;
      this.watersupply = null;
      this.cleaningschedule = null;
      this.district = null;
      this.images = [];
      this.documents = [];
    },

    uploadImage(file) {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
      reader.onerror = function(error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    clearImg() {
      if (
        confirm("Желаете удалить фото у объекта " + this.properties.id + "?")
      ) {
        this.removeImg(this.properties.id);
        this.imageUrl = "";
      } else {
        this.uploadImage();
      }
    },

    async getPassportData() {
      let response = await Vue.axios.get(`${URL_API}fountaintype`);
      this.passport_fontaintype = response.data;
      response = await Vue.axios.get(`${URL_API}bowltype`);
      this.passport_bowltype = response.data;
      response = await Vue.axios.get(`${URL_API}injectortype`);
      this.passport_injectortype = response.data;
      response = await Vue.axios.get(`${URL_API}fountaincondition`);
      this.passport_fountaincondition = response.data;
      response = await Vue.axios.get(`${URL_API}watersupply`);
      this.passport_watersupply = response.data;
      response = await Vue.axios.get(`${URL_API}cleaningschedule`);
      this.passport_cleaningschedule = response.data;

      if (this.properties?.id) {
        response = await Vue.axios.get(
            `${URL_API}fountain/view/${this.properties.id}`
        );
        const data = response.data;
        this.name = data.name;
        this.address = data.address;
        this.construction_year = data.construction_year;
        this.type_id = data.type_id;
        this.bowl_id = data.bowl_id;
        this.bowl_number = data.bowl_number;
        this.area = data.area;
        this.volume = data.volume;
        this.supply_lamps = data.supply_lamps;
        this.injectors = data.injectors;
        this.condition_id = data.condition_id;
        this.submersible_pumps = data.submersible_pumps;
        this.centrifugal_pumps = data.centrifugal_pumps;
        this.spotlights = data.spotlights;
        this.video_cameras = data.video_cameras;
        this.rgb_backlight = data.rgb_backlight;
        this.electricity = data.electricity;
        this.water_supply_id = data.water_supply_id;
        this.cadastre_number = data.cadastre_number;
        this.cleaning_schedule_id = data.cleaning_schedule_id;
        this.note = data.note;
        this.district_id = data.district_id;
        this.injectortypes = data.injectortypes;
        this.fountaintype = data.fountaintype;
        this.bowltype = data.bowltype;
        this.fountaincondition = data.fountaincondition;
        this.watersupply = data.watersupply;
        this.cleaningschedule = data.cleaningschedule;
        this.district = data.district;
        this.images = data.images;
        this.imageUrl = data.images.length ? URL + data.images[0].path : "";
        this.documents = data.documents;
      }
    }
  },

  mounted() {
    this.getPassportData();
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
