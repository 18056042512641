<template>
  <v-container fluid>
    <v-row dense>

      <v-spacer></v-spacer>

    </v-row>

      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-x-hidden"
        max-height="92vh"
      >
        <v-row dense>
          <v-col align="center" justify="center">
            <vl-map
              data-projection="EPSG:4326"
              style="width: 100%; height: 100vh;"
              :key="mapChange"
              ref="map"
            >
              <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

              <vl-layer-tile
                v-for="(item, index) in $store.state.settings.maps"
                :z-index="index"
                :opacity="item.alpha / 100"
                :key="item.id"
              >
                <vl-source-osm
                  v-if="(item.id === 'osm') & item.visible"
                ></vl-source-osm>
                <vl-source-xyz
                  v-if="(item.id === '2gis') & item.visible"
                  url="http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd"
                ></vl-source-xyz>
                <vl-source-bingmaps
                  v-if="(item.id === 'bingMapsAerial') & item.visible"
                  :api-key="$store.state.settings.apiKeyBing"
                  imagery-set="Aerial"
                ></vl-source-bingmaps>
                <vl-source-bingmaps
                  v-if="(item.id === 'bingMapsAerialWithLabels') & item.visible"
                  :api-key="$store.state.settings.apiKeyBing"
                  imagery-set="Aerial"
                ></vl-source-bingmaps>
                <vl-source-xyz
                  v-if="(item.id === 'mapbox') & item.visible"
                  url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGFnb3QiLCJhIjoiY2lveTNjbnRnMDBjOXZtbTJqNGNsNmk1NCJ9.bvTUlzXKG4A2ZejsF1v2Hw"
                ></vl-source-xyz>
                <vl-source-xyz
                  v-if="(item.id === 'arcgis') & item.visible"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  :tile-load-function="arcgisLoad"
                ></vl-source-xyz>
              </vl-layer-tile>

              <vl-layer-vector v-if="!onlyfountain" id="improvementLayer" :z-index="101">
                <vl-source-vector :loader="getSquares" />
                <vl-style-func :factory="squareStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="improvementDraw" :z-index="102">
                <vl-source-vector
                  :features.sync="drawFeatures"
                  ident="featuresDraw"
                />
              </vl-layer-vector>
              <vl-layer-vector v-if="onlyfountain" id="layerFountain" :z-index="103">
                <vl-source-vector
                    :features.sync="featuresFountain"
                    ident="featuresFountain"
                />
                <vl-style-func :factory="fountainStyle"></vl-style-func>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="architecTarget.id && isDraw && onlyfountain === false"
                :type="architecTarget.geometry_type"
                source="featuresDraw"
                @drawend="drawEnd"
                stop-click
              />
              <vl-interaction-draw
                  v-if="isDraw && onlyfountain"
                  :type="architecTarget.geometry_type"
                  source="featuresDraw"
                  @drawend="drawEndFountain"
                  stop-click
              />

              <vl-interaction-select
                :features.sync="selectedFeatures"
                :layers="[
                  'improvementLayer',
                  'improvementDraw',
                  'playground',
                  'monument',
                  'bench',
                  'urn',
                  'lamp',
                  'fountain',
                  'other',
                  'irrigation',
                  'paths'
                ]"
                @select="onSelect"
                @unselect="onUnselect"
                ref="select"
                ident="featuresSelected"
              />
              <vl-interaction-select
                  :features.sync="selectedFeaturesFountain"
                  :layers="[
                  'layerFountain',
                  'improvementDraw'
                ]"
                  ident="featuresSelectedFountain"
                  ref="selectFountain"
                  @select="onSelectFountain"
                  @unselect="onUnselectFountain"
              />
              <vl-interaction-modify
                  source="featuresSelected"
                  @modifyend="onModifyEnd"
              />
              <vl-interaction-modify
                  source="featuresSelectedFountain"
                  @modifyend="onModifyEnd"
              />






              <vl-layer-vector id="architectReportDraw" :z-index="104">
                <vl-source-vector
                  :features.sync="drawReportFeatures"
                  ident="architectDraw"
                ></vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="selectionObj"
                type="Polygon"
                source="architectDraw"
                @drawstart="drawStartReport"
                @drawend="drawEndReport"
                stop-click
              />
              <vl-interaction-select
                :features.sync="selectedReportFeatures"
                :layers="['architectReportDraw']"
                ident="featuresReportSelected"
                @select="onReportSelect"
                @unselect="onReportUnselect"
                ref="selectReport"
              />

              <vl-layer-vector id="playground" v-if="playground">
                <vl-source-vector :loader="getPlayground" />
                <vl-style-func :factory="playgroundStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="monument" v-if="monument">
                <vl-source-vector :loader="getMonument" />
                <vl-style-func :factory="monumentStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="bench" v-if="bench">
                <vl-source-vector :loader="getBench" />
                <vl-style-func :factory="benchStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="urn" v-if="urn">
                <vl-source-vector :loader="getUrn" />
                <vl-style-func :factory="urnStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="lamp" v-if="lamp">
                <vl-source-vector :loader="getLamp" />
                <vl-style-func :factory="lampStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="fountain" v-if="fountain">
                <vl-source-vector :loader="getFountain" />
                <vl-style-func :factory="fountainStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="other" v-if="other">
                <vl-source-vector :loader="getOther" />
              </vl-layer-vector>
              <vl-layer-vector id="irrigation" v-if="irrigation">
                <vl-source-vector :loader="getIrrigation" />
                <vl-style-func :factory="irrigationStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="paths" v-if="paths">
                <vl-source-vector :loader="getPaths" />
                <vl-style-func :factory="pathStyle" />
              </vl-layer-vector>
              <vl-layer-vector id="osmFountains" v-if="osmFountains">
                <vl-source-vector :loader="getOSMFountains" />
              </vl-layer-vector>
              <vl-layer-vector id="osmPlayground" v-if="osmPlayground">
                <vl-source-vector :loader="getOSMPlayground" />
              </vl-layer-vector>
            </vl-map>
          </v-col>
          <FountainPassport
              v-if="fountainPassport && drawerAppeal === false"
              :saveFountain="saveFountain"
              :deleteFountain="deleteFountain"
              :properties="properties"
              :isDraw="isDraw"
              @open-appeal="openAppeal"
          />
          <SquarePassport
            v-if="squarePassport"
            :setSquare="setSquare"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-square="removeSquare"
          />
          <ArchitectPassport
            v-if="passport"
            :setArchutecture="setArchutecture"
            :architectureName="architectureName"
            @remove-architecture="removeArchitecture"
          />
          <ArchitectRepPassport
            v-if="selectionObj && reportPassport"
            :selectedObjReport="selectedObjReport"
            :reportCount="reportCount"
          />
        </v-row>
      </v-sheet>
      <v-navigation-drawer
        v-show="drawerFilter"
        width="275"
        bottom
        right
        absolute
      >
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon size="36">mdi-filter</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              ><h3 class="text-wrap">
                Управление фильтрами
              </h3>
            </v-list-item-title>
            <v-list-item-action>
              <v-btn icon @click="drawerFilter = !drawerFilter">
                <v-icon size="32">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Детская площадка"
              :value="playground"
              @change="playground = !playground"
            ></v-checkbox>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Памятник"
              :value="monument"
              @change="monument = !monument"
            ></v-checkbox>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Скамейка"
              :value="bench"
              @change="bench = !bench"
            ></v-checkbox>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Урна"
              :value="urn"
              @change="urn = !urn"
            ></v-checkbox>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Фонарь"
              :value="lamp"
              @change="lamp = !lamp"
            ></v-checkbox>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Фонтан"
              :value="fountain"
            ></v-checkbox>
          </v-list-item>
          <v-list-item style="height: 48px">
            <v-checkbox
              label="Тропинки"
              :value="paths"
              @change="paths = !paths"
            ></v-checkbox>
          </v-list-item>
        </v-list>
        <v-col align="center">
          <v-btn
            type="button"
            color="info"
            class="ml-2 mb-2 font-weight-bold"
            @click="squareExcel"
            >Отчет по паркам
          </v-btn>
        </v-col>
      </v-navigation-drawer>

      <AppealPassport
          :drawer="drawerAppeal"
          :setAppeal="setAppeal"
          @close-appeal="closeAppeal"
      />

      <v-card>
        <v-snackbar v-model="snackbar" :timeout="timeout" top right outlined>
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import { URL_API } from "../settings/apiConnect";
import { mapActions } from "vuex";
import { Style, Icon, Fill, Stroke } from "ol/style";
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";

import lampImg from "@/assets/lamp.png";
import benchImg from "@/assets/bench.png";
import urnImg from "@/assets/urn.png";
import monumentImg from "@/assets/monument.png";
import fountainImg from "@/assets/fountain.png";

import SquarePassport from "../passports/SquarePassport.vue";
import ArchitectPassport from "../passports/ArchitectPassport.vue";
import ArchitectRepPassport from "../passports/ArchitectRepPassport.vue";
import FountainPassport from "@/passports/FountainPassport.vue";
import TileState from "ol/TileState";
import AppealPassport from "@/passports/AppealPassport.vue";

export default {
  name: "ImprovementTab",

  data() {
    return {
      mapChange: 0,
      zoom: 13,
      center: [76.889709, 43.238949],
      formatWKT: new WKT(),
      formatGeoJSON: new GeoJSON(),
      drawFeatures: [],
      selectedFeatures: [],
      drawReportFeatures: [],
      selectedReportFeatures: [],
      architecTarget: { id: 0, name: "РЕДАКТИРОВАНИЕ", geometry_type: "Point" },
      properties: {},
      architectureName: "",
      district: {},
      snackbar: false,
      text: "",
      timeout: 2000,
      isDraw: false,
      editObj: false,
      selectionObj: false,
      passport: false,
      squarePassport: false,
      reportPassport: false,
      playground: false,
      monument: false,
      bench: false,
      urn: false,
      lamp: false,
      fountain: false,
      other: false,
      irrigation: false,
      paths: false,
      osmFountains: false,
      osmPlayground: false,
      drawerFilter: false,

      onlyfountain: false,
      featuresFountain: [],
      selectedFeaturesFountain: [],
      fountainPassport: false,
      geometries: [],
      drawerAppeal: false
    };
  },

  methods: {
    ...mapActions([
      "getSquares",
      "getSquaresById",
      "getPlayground",
      "getMonument",
      "getBench",
      "getUrn",
      "getLamp",
      "getFountain",
      "getOther",
      "getIrrigation",
      "getPaths",
      "getOSMFountains",
      "getOSMPlayground",
      "createSquares",
      "pushSquares",
      "destroySquares",
      "getDistrictByArea",
      "createArchitecture",
      "pushArchitecture",
      "destroyArchitecture",
      "createAppeal",
      "getAppealsByPlant"
    ]),

    async saveFountain(fountainData) {
      const lastSelect = this.selectedFeaturesFountain[this.selectedFeaturesFountain.length - 1];
      if(lastSelect.properties) {
        // Существующий объект
        let formData = new FormData();
        formData.append('id', lastSelect.properties.id);

        for (const [key, value] of Object.entries(fountainData)) {
          if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                formData.append(`${key}[${index}]`, item);
              });
            } else {
              formData.append(key, value);
            }
          }
        }

        if (this.geometries.length > 0) {
          formData.append('geometry', this.geometries[this.geometries.length-1].geometry);
        }

        await Vue.axios.post(`${URL_API}fountain/update`, formData);
      } else {
        // Новый объект
        const geometry = this.formatGeoJSON.readFeature(lastSelect.geometry);
        const wkt = this.formatWKT.writeFeature(geometry);

        let formData = new FormData();
        formData.append('geometry', wkt);

        for (const [key, value] of Object.entries(fountainData)) {
          if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                formData.append(`${key}[${index}]`, item);
              });
            } else {
              formData.append(key, value);
            }
          }
        }

        await Vue.axios.post(`${URL_API}fountain/store`, formData);
      }
      this.geometries = [];
      const response = await Vue.axios.get(`${URL_API}fountain`);
      this.featuresFountain = response.data.features;
      this.fountainPassport = false;
    },

    async deleteFountain() {
      const lastSelect = this.selectedFeaturesFountain[this.selectedFeaturesFountain.length - 1];
      if (lastSelect.properties) {
        let next = confirm("Вы действительно хотите удалить фонтан?");
        if (next) {
          let formData = new FormData();
          formData.append('id', lastSelect.properties.id);

          await Vue.axios.post(`${URL_API}fountain/destroy`, formData);

          const response = await Vue.axios.get(`${URL_API}fountain`);
          this.featuresFountain = response.data.features;
          this.fountainPassport = false;
        }
      }
    },

    onModifyEnd(evt) {
      const feature = evt.json[0];
      this.geometries.push({ id: feature.properties.id, geometry: this.formatWKT.writeFeature(this.formatGeoJSON.readFeature(evt.json[0].geometry)) });
    },

    fountainStyle() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: fountainImg
            })
          })
        ];
      };
    },

    lampStyle() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: lampImg
            })
          })
        ];
      };
    },
    benchStyle() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: benchImg
            })
          })
        ];
      };
    },
    urnStyle() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: urnImg
            })
          })
        ];
      };
    },
    monumentStyle() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: monumentImg
            })
          })
        ];
      };
    },
    playgroundStyle() {
      return () => {
        return [
          new Style({
            fill: new Fill({ color: "rgba(253, 69, 38, 0.4)" }),
            stroke: new Stroke({
              color: "rgba(253, 69, 38, 0.4)",
              width: 3
            })
          })
        ];
      };
    },
    squareStyle() {
      return () => {
        return [
          new Style({
            fill: new Fill({ color: "rgba(59, 190, 13, 0.3)" }),
            stroke: new Stroke({
              color: "rgba(59, 190, 13, 0.3)",
              width: 3
            })
          })
        ];
      };
    },
    pathStyle() {
      return () => {
        return [
          new Style({
            fill: new Fill({ color: "#947151" }),
            stroke: new Stroke({
              color: "#947151",
              width: 3
            })
          })
        ];
      };
    },
    irrigationStyle() {
      return () => {
        return [
          new Style({
            fill: new Fill({ color: "#00A3FF" }),
            stroke: new Stroke({
              color: "#00A3FF",
              width: 3
            })
          })
        ];
      };
    },
    async setSquare({
      name,
      address,
      area,
      year,
      plant_amount,
      foliage_amount,
      pine_amount,
      bush_amount,
      hedge_length,
      lawn_area,
      garden_area,
      architecture_amount,
      fountain_amount,
      beautification_amount,
      bench_amount,
      trashcan_amount,
      lamp_amount,
      irrigation_length,
      road_length,
      company
    }) {
      let feature = this.formatGeoJSON.readFeature(this.selectedFeatures[0]);
      let wktRepresenation = this.formatWKT.writeFeature(feature);
      let properties = this.selectedFeatures[0].properties;
      if (properties) {
        await this.pushSquares({
          id: properties.id,
          geometry: wktRepresenation,
          name: name,
          address: address,
          area: area,
          year: year,
          plant_amount: plant_amount,
          foliage_amount: foliage_amount,
          pine_amount: pine_amount,
          bush_amount: bush_amount,
          hedge_length: hedge_length,
          lawn_area: lawn_area,
          garden_area: garden_area,
          architecture_amount: architecture_amount,
          fountain_amount: fountain_amount,
          beautification_amount: beautification_amount,
          bench_amount: bench_amount,
          trashcan_amount: trashcan_amount,
          lamp_amount: lamp_amount,
          irrigation_length: irrigation_length,
          road_length: road_length,
          company: company,
          district_id: this.district.id
        });
      } else {
        await this.createSquares({
          geometry: wktRepresenation,
          name: name,
          address: address,
          area: area,
          year: year,
          plant_amount: plant_amount,
          foliage_amount: foliage_amount,
          pine_amount: pine_amount,
          bush_amount: bush_amount,
          hedge_length: hedge_length,
          lawn_area: lawn_area,
          garden_area: garden_area,
          architecture_amount: architecture_amount,
          fountain_amount: fountain_amount,
          beautification_amount: beautification_amount,
          bench_amount: bench_amount,
          trashcan_amount: trashcan_amount,
          lamp_amount: lamp_amount,
          irrigation_length: irrigation_length,
          road_length: road_length,
          company: company,
          district_id: this.district.id
        });
      }
      setTimeout(() => {
        this.drawFeatures = [];
        this.squarePassport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.mapChange++;
      }, 1000);
    },
    async setArchutecture() {
      let feature = this.formatGeoJSON.readFeature(this.selectedFeatures[0]);
      let wktRepresenation = this.formatWKT.writeFeature(feature);
      let properties = this.selectedFeatures[0].properties;
      if (properties) {
        await this.pushArchitecture({
          id: properties.id,
          geometry: wktRepresenation,
          architecture_type_id: this.architecTarget.id
        });
      } else {
        await this.createArchitecture({
          geometry: wktRepresenation,
          architecture_type_id: this.architecTarget.id
        });
      }
      setTimeout(() => {
        this.drawFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.mapChange++;
      }, 1000);
    },
    async removeSquare() {
      let properties = this.selectedFeatures[0].properties;
      if (!properties) {
        this.drawFeatures = this.drawFeatures.filter(
          item =>
            !(
              JSON.stringify(item.geometry) ===
              JSON.stringify(this.selectedFeatures[0].geometry)
            )
        );
        return;
      } else {
        await this.destroySquares(properties.id);
      }
      setTimeout(() => {
        this.drawFeatures = [];
        this.squarePassport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.mapChange++;
      }, 2000);
    },
    async removeArchitecture() {
      let properties = this.selectedFeatures[0].properties;
      if (!properties) {
        this.drawFeatures = this.drawFeatures.filter(
          item =>
            !(
              JSON.stringify(item.geometry) ===
              JSON.stringify(this.selectedFeatures[0].geometry)
            )
        );
        return;
      } else {
        await this.destroyArchitecture(properties.id);
      }
      setTimeout(() => {
        this.drawFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.mapChange++;
      }, 2000);
    },

    drawEndFountain() {
      this.selectedFeaturesFountain = [];
      this.selectedFeaturesFountain.push(
          this.drawFeatures[this.drawFeatures.length - 1]
      );
      this.fountainPassport = true;
    },
    async onSelectFountain(feature) {
      console.log("fountain", feature);
      this.properties = this.selectedFeaturesFountain[0].properties;
      this.fountainPassport = true;
    },
    async onUnselectFountain(feature) {
      console.log("fountain", feature);
      this.fountainPassport = false;
      this.properties = {};
      this.drawerAppeal = false;
    },

    drawEnd() {
      this.selectedFeatures = [];
      this.selectedFeatures.push(
          this.drawFeatures[this.drawFeatures.length - 1]
      );
    },
    async onSelect(feature) {
      if (this.isDraw || this.editObj) {
        let props;
        if (!feature.json.properties) {
          props = { architecture_type_id: this.architecTarget.id };
          this.architectureTypes.find(e => {
            if (this.architecTarget.id === e.id) this.architectureName = e.name;
          });
        } else {
          props = await this.getSquaresById(feature.json.properties.id);
          this.architectureTypes.find(e => {
            if (feature.json.properties.architecture_type_id === e.id)
              this.architectureName = e.name;
          });
        }
        props.geometry = feature.json.geometry;

        this.architecTarget = this.architectureTypes.find(e => {
          if (!feature.json.properties) {
            return e.id === this.architecTarget.id;
          } else {
            if (feature.json.properties.architecture_type_id) {
              return e.id === feature.json.properties.architecture_type_id;
            } else {
              return e.id === 10;
            }
          }
        });

        if (this.architecTarget.id !== 10) {
          setTimeout(() => {
            if (JSON.stringify(this.properties) === JSON.stringify({})) {
              this.properties = props;
            } else if (feature.json.properties)
              if (feature.json.properties.id) this.properties = props;
          }, 1);
          this.passport = true;
        } else {
          this.squarePassport = true;
          setTimeout(() => {
            if (JSON.stringify(this.properties) === JSON.stringify({})) {
              this.properties = props;
            } else if (feature.json.properties)
              if (feature.json.properties.id) this.properties = props;
          }, 1);
          let geometry = this.formatGeoJSON.readFeature(feature.json.geometry);
          let wktRepresenation = this.formatWKT.writeFeature(geometry);
          this.district = await this.getDistrictByArea(wktRepresenation);
        }
      }
    },
    onUnselect(feature) {
      if (feature.json.properties) {
        this.properties = {};
        this.architectureName = "";
        this.passport = false;
        this.squarePassport = false;
      }
    },
    onCreate() {
      this.isDraw = !this.isDraw;
      if (!this.isDraw) {
        this.drawFeatures = [];
        this.selectedFeatures = [];
        this.passport = false;
        this.fountainPassport = false;
        this.squarePassport = false;
      }
    },
    onEdit() {
      if (this.selectedFeatures.length > 1 || this.selectedFeaturesFountain.length > 1) {
        let next = confirm("Вы хотите отменить копирование группы?");
        if (!next) {
          return;
        }
      }
      this.editObj = !this.editObj;
      if (this.editObj) {
        this.$refs.select?.setActive(true);
        this.$refs.selectFountain?.setActive(true);
      } else {
        this.$refs.select?.setActive(false);
        this.$refs.selectFountain?.setActive(false);
        this.selectedFeatures = [];
        this.selectedFeaturesFountain = [];
      }
    },
    async squareExcel() {
      await Vue.axios({
        url: `${URL_API}square/exportExcel`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },
    selectionTool() {
      this.selectionObj = !this.selectionObj;
      this.selectedReportFeatures = [];
      this.drawReportFeatures = [];
    },
    async onReportSelect(feature) {
      this.passport = false;
      this.selectedFeatures = [];
      let geometry = this.formatGeoJSON.readFeature(feature.json.geometry);
      let wktRepresenation = this.formatWKT.writeFeature(geometry);
      this.reportGeometry = wktRepresenation;
      let response;

      let architecture_types = "";
      let arch_ids = [];
      if (this.playground) {
        arch_ids.push(1);
      }
      if (this.monument) {
        arch_ids.push(2);
      }
      if (this.bench) {
        arch_ids.push(3);
      }
      if (this.urn) {
        arch_ids.push(4);
      }
      if (this.lamp) {
        arch_ids.push(5);
      }
      if (this.fountain) {
        arch_ids.push(6);
      }
      if (this.irrigation) {
        arch_ids.push(8);
      }
      if (this.paths) {
        arch_ids.push(9);
      }
      if (arch_ids.length > 0) {
        architecture_types = "architecture_type_id=" + arch_ids.join(",") + "&";
      }

      let layer = "";
      if (this.layerTarget !== 0)
        layer = `&layer_id=${this.$store.state.settings.layerTarget}&`;

      response = await Vue.axios.get(
        `${URL_API}architectureType/getbyfilter?${architecture_types}${layer}geometry=${this.reportGeometry}`
      );
      this.reportCount = response.data;
      this.reportPassport = true;
    },
    drawEndReport() {
      this.selectedReportFeatures = [];
      this.selectedReportFeatures.push(
        this.drawReportFeatures[this.drawReportFeatures.length - 1]
      );
    },
    drawStartReport() {
      this.selectedReportFeatures = [];
      this.drawReportFeatures = [];
    },
    onReportUnselect() {
      this.reportPassport = false;
    },
    async selectedObjReport() {
      let amount = this.reportCount.reduce(
        (total, array) => total + array.count,
        0
      );
      if (amount > 5000000) {
        this.text = amount + " объектов слишком много для формирования отчета";
        this.snackbar = true;
      } else {
        let isContinue = confirm(`Для отчёта выбрано ${amount} объектов. Время формирования отчёта ~${amount/300000} минут. Продолжить?`);
        if (!isContinue) return;
        let architecture_types = "";
        let arch_ids = [];
        if (this.playground) {
          arch_ids.push(1);
        }
        if (this.monument) {
          arch_ids.push(2);
        }
        if (this.bench) {
          arch_ids.push(3);
        }
        if (this.urn) {
          arch_ids.push(4);
        }
        if (this.lamp) {
          arch_ids.push(5);
        }
        if (this.fountain) {
          arch_ids.push(6);
        }
        if (this.irrigation) {
          arch_ids.push(8);
        }
        if (this.paths) {
          arch_ids.push(9);
        }
        if (arch_ids.length > 0) {
          architecture_types =
            "architecture_type_id=" + arch_ids.join(",") + "&";
        }
        let layer = "";
        if (this.layerTarget !== 0)
          layer = `&layer_id=${this.$store.state.settings.layerTarget}&`;
        await Vue.axios({
          url: `${URL_API}architectureType/getbyfilterExcel?${architecture_types}${layer}geometry=${this.reportGeometry}`,
          method: "GET",
          responseType: "blob"
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
        });
        this.reportPassport = false;
        this.selectionObj = false;
        this.selectedReportFeatures = [];
        this.drawReportFeatures = [];
      }
    },
    arcgisLoad(imageTile, src) {
      let map = this.$refs.map;
      let zoom = map.getView().getZoom();
      if (zoom > 19) {
        imageTile.setState(TileState.ERROR);
      } else {
        imageTile.getImage().src = src;
      }
    },

    averageCoordinates(coordinates, type) {
      let totalX = 0, totalY = 0, count = 0;

      if (type === 'MultiPolygon') {
        coordinates.forEach(polygon => {
          polygon[0].forEach(coord => {
            totalX += coord[0];
            totalY += coord[1];
            count++;
          });
        });
      } else {
        coordinates.forEach(coord => {
          totalX += coord[0];
          totalY += coord[1];
          count++;
        });
      }

      return [totalX / count, totalY / count];
    },
    processFeatures(features) {
      this.featuresFountain = features.map(feature => {
        const geometryType = feature.geometry.type;
        const avgCoord = this.averageCoordinates(feature.geometry.coordinates, geometryType);
        return {
          type: 'Feature',
          properties: {
            ...feature.properties
          },
          geometry: {
            type: 'Point',
            coordinates: avgCoord
          }
        };
      });
    },

    async setAppeal({ full_name, email, phone, body, image }) {
      this.$store.commit("updateCount");
      let properties = this.selectedFeaturesFountain[0].properties;
      await this.createAppeal({
        full_name: full_name,
        email: email,
        phone: phone,
        body: body,
        image: image,
        plant_id: properties.id,
        object_type: 3
      });
      setTimeout(() => {
        this.selectedFeaturesFountain = [];
        this.drawFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.drawerAppeal = false;
      }, 1);
    },

    openAppeal() {
      this.drawerAppeal = true;
    },

    closeAppeal() {
      this.drawerAppeal = false;
    }
  },

  async mounted() {
    this.$refs.select?.setActive(false);
    this.$refs.selectFountain?.setActive(true);

    this.onlyfountain = true;

    const response = await Vue.axios.get(`${URL_API}fountain`);
    this.processFeatures(response.data.features);
  },

  components: {
    AppealPassport,
    FountainPassport,
    SquarePassport,
    ArchitectPassport,
    ArchitectRepPassport
  }
};
</script>

<style scoped>
.ol-attribution {
  display: none;
}
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}
::v-deep .v-list-item__action {
  margin: 0;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
