<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт водохранилища</h3>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Широта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ latitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Долгота</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ longitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Название водоема</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ name }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Введен в эксплуатацию</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ exploitation_year }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. район</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ district_name }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn
              color="warning"
              style="color: black"
              @click="$emit('open-appeal')"
          >Сообщить об ошибке</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "ReservoirPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_pondtype: [],
      passport_serviceorganization: [],
      passport_belonging: [],

      name: null,
      pond_type_id: null,
      purpose: null,
      water_source: null,
      district_id: null,
      latitude: null,
      longitude: null,
      surface_area: null,
      perimeter: null,
      average_depth: null,
      volume: null,
      exploitation_year: null,
      belonging: null,
      note: null,
      separation_by_location: null,
      serviceorganizations: [],

      district_name: null,
    };
  },

  methods: {
    saveClick() {
      const data = {
        name: this.name,
        pond_type_id: this.pond_type_id,
        purpose: this.purpose,
        water_source: this.water_source,
        district_id: this.district_id,
        latitude: this.latitude,
        longitude: this.longitude,
        surface_area: this.surface_area,
        perimeter: this.perimeter,
        average_depth: this.average_depth,
        volume: this.volume,
        exploitation_year: this.exploitation_year,
        belonging: this.belonging,
        note: this.note,
        separation_by_location: this.separation_by_location
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.name = null;
      this.pond_type_id = null;
      this.purpose = null;
      this.water_source = null;
      this.district_id = null;
      this.latitude = null;
      this.longitude = null;
      this.surface_area = null;
      this.perimeter = null;
      this.average_depth = null;
      this.volume = null;
      this.exploitation_year = null;
      this.belonging = null;
      this.note = null;
      this.separation_by_location = null;
      this.serviceorganizations = [];
    },

  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}pondtype`);
    this.passport_pondtype = response.data;
    let response2 = await Vue.axios.get(`${URL_API}serviceorganization`);
    this.passport_serviceorganization = response2.data;

    if (this.properties?.id) {
      response = await Vue.axios.get(
          `${URL_API}reservoir/view/${this.properties.id}`
      );
      const data = response.data;
      this.name = data.name;
      this.pond_type_id = data.pond_type_id;
      this.purpose = data.purpose;
      this.water_source = data.water_source;
      this.district_id = data.district_id;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.surface_area = data.surface_area;
      this.perimeter = data.perimeter;
      this.average_depth = data.average_depth;
      this.volume = data.volume;
      this.exploitation_year = data.exploitation_year;
      this.belonging = data.belonging;
      this.note = data.note;
      this.separation_by_location = data.separation_by_location;
      this.serviceorganizations = data.serviceorganizations;

      if (this.district_id) {
        this.district_name = data.district.name;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
