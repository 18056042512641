<template>
  <v-container fluid>
    <v-row dense>
      <v-list dense class="d-flex flex-row align-center">
        <v-img src="@/assets/tree_green.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Здоровые
        </v-list-item-action-text>

        <v-img src="@/assets/tree_red.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Аварийные
        </v-list-item-action-text>

        <v-img src="@/assets/tree_yellow.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Сухостой
        </v-list-item-action-text>

        <v-img src="@/assets/tree_orange.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Ослабленные
        </v-list-item-action-text>

        <v-img src="@/assets/tree_blue.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Угнетенные
        </v-list-item-action-text>

        <v-img src="@/assets/tree_brown.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Усыхающие
        </v-list-item-action-text>

        <v-img src="@/assets/red_book_small.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Краснокнижные
        </v-list-item-action-text>

        <v-img src="@/assets/pine_green.png" height="30" />
        <v-list-item-action-text class="mr-3">
          Хвойные
        </v-list-item-action-text>
      </v-list>

      <v-spacer></v-spacer>

      <v-list-item-group class="pa-1">
        <v-list-item-action class="ma-0 mr-1">
          <v-list-item-title>Управление отображением</v-list-item-title>
        </v-list-item-action>
        <v-btn
          icon
          height="34"
          width="34"
          @click="changeDrawerFilter"
          outlined
          title="Фильтр"
          :class="{ 'primary--text': drawerFilter }"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-list-item-group>
    </v-row>

    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-x-hidden"
      max-height="87vh"
    >
      <v-row dense>
        <v-col align="center" justify="center">
          <vl-map
            :load-tiles-while-animating="true"
            :load-tiles-while-interacting="true"
            data-projection="EPSG:4326"
            style="height: 94vh;"
            :key="$store.state.settings.mapChange"
            ref="map"
            @moveend="onMoveEnd"
          >
            <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

            <vl-layer-tile id="osm">
              <vl-source-osm></vl-source-osm>
            </vl-layer-tile>

            <vl-layer-vector id="layerAPI">
              <vl-source-vector
                :features.sync="features"
                ident="featuresAPI"
                ref="vectorSource"
              />
              <vl-style-func :factory="plantStyle" />
            </vl-layer-vector>
            <vl-interaction-select
              :features.sync="selectedFeatures"
              :layers="['layerAPI']"
              @select="onSelect"
              @unselect="onUnselect"
              ident="featuresSelected"
            />
            <vl-layer-vector :min-resolution="5" :z-index="100">
              <vl-source-vector :loader="getDistrictQuantity" />
              <vl-style-func :factory="districtStyle" />
            </vl-layer-vector>
          </vl-map>
        </v-col>

        <v-navigation-drawer :value="typeTarget === 1 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <TreePassport
                  :properties="properties"
                  :geoCoordinates="geoCoordinates"
                  :district="district"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 2 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <BushPassport
                  :properties="properties"
                  :geoCoordinates="geoCoordinates"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 3 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <CurtainTreePassport
                  :properties="properties"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 4 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <CurtainBushPassport
                  :properties="properties"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 5 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <HedgePassport
                  :properties="properties"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 6 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <FlowerGardenPassport
                  :properties="properties"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 7 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <LawnPassport
                  :properties="properties"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

        <v-navigation-drawer :value="typeTarget === 8 && passport" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
          <VinesPassport
                  :properties="properties"
                  :district="district"
                  :setAppeal="setAppeal"
                  :appealsByPlant="appealsByPlant"
                  @open-appeal="openAppeal"
          />
        </v-navigation-drawer>

      </v-row>
    </v-sheet>
    <Filters
      :drawer="drawerFilter"
      @close-filters="changeDrawerFilter"
      :getDistrictQuantity="getDistrictQuantity"
    />

    <AppealPassport
      :drawer="drawerAppeal"
      :setAppeal="setAppeal"
      @close-appeal="closeAppeal"
    />

    <v-card>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        top
        right
        outlined
        color="primary"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { fromExtent } from "ol/geom/Polygon";
import Vue from "vue";
import { mapActions } from "vuex";
import { URL_API } from "../settings/apiConnect";
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";
import { Circle, Fill, Icon, Stroke, Style, Text } from "ol/style";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";

import TreePassport from "../passports/TreePassport.vue";
import BushPassport from "../passports/BushPassport.vue";
import CurtainTreePassport from "../passports/CurtainTreePassport.vue";
import CurtainBushPassport from "../passports/CurtainBushPassport.vue";
import FlowerGardenPassport from "../passports/FlowerGardenPassport.vue";
import LawnPassport from "../passports/LawnPassport.vue";
import HedgePassport from "../passports/HedgePassport.vue";
import VinesPassport from "../passports/VinesPassport.vue";
import Filters from "@/filters/Filters.vue";
import AppealPassport from "../passports/AppealPassport.vue";

import treeGreenImg from "@/assets/tree_green.png";
import treeOrangeImg from "@/assets/tree_orange.png";
import treeBlueImg from "@/assets/tree_blue.png";
import treeBrownImg from "@/assets/tree_brown.png";
import treeYellowImg from "@/assets/tree_yellow.png";
import treeRedImg from "@/assets/tree_red.png";
import bushGreenImg from "@/assets/bush_green.png";
import bushOrangeImg from "@/assets/bush_orange.png";
import bushBlueImg from "@/assets/bush_blue.png";
import bushBrownImg from "@/assets/bush_brown.png";
import bushYellowImg from "@/assets/bush_yellow.png";
import bushRedImg from "@/assets/bush_red.png";
import flowerGardenImg from "@/assets/flower_garden.png";
import cutdownImg from "@/assets/cutdown.png";
import lawnImg from "@/assets/lawn.png";
import redBookBlueImg from "@/assets/red_book_blue.png";
import redBookBrownImg from "@/assets/red_book_brown.png";
import redBookGreenImg from "@/assets/red_book_green.png";
import redBookOrangeImg from "@/assets/red_book_orange.png";
import redBookRedImg from "@/assets/red_book_red.png";
import redBookYellowImg from "@/assets/red_book_yellow.png";
import pineBlueImg from "@/assets/pine_blue.png";
import pineBrownImg from "@/assets/pine_brown.png";
import pineGreenImg from "@/assets/pine_green.png";
import pineOrangeImg from "@/assets/pine_orange.png";
import pineRedImg from "@/assets/pine_red.png";
import pineYellowImg from "@/assets/pine_yellow.png";
import warningImg from "@/assets/warning.png";

export default {
  name: "MapScreen",

  data() {
    return {
      zoom: 13,
      center: [76.889709, 43.238949],
      features: [],
      selectedFeatures: [],
      formatWKT: new WKT(),
      formatGeoJSON: new GeoJSON(),
      cancelToken: null,
      passport: false,
      properties: {},
      geoCoordinates: {},
      district: {},
      typeTarget: 0,
      drawerFilter: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      appealsByPlant: [],
      drawerAppeal: false
    };
  },

  computed: {
    districtTarget: {
      get() {
        return this.$store.state.settings.ids_districts;
      },
      set(value) {
        this.$store.commit("updateFilteredDisctricts", value);
      }
    }
  },

  watch: {
    districtTarget: function() {
      this.getDistrictQuantity();
      this.$store.state.settings.mapChange++;
    }
  },

  methods: {
    ...mapActions([
      "getDistrictsData",
      "getPlantById",
      "getDistrictByArea",
      "getBreedsData",
      "getTypesData",
      "getFlowersData",
      "getVinesData",
      "getFlowerCategoryData",
      "getFlowerGardenData",
      "getVerminsData",
      "getDiseasesData",
      "getStatusesData",
      "getEventsData",
      "getSanitaryData",
      "getCategoriesData",
      "getBushData",
      "createAppeal",
      "getAppealsByPlant"
    ]),

    tabClicked(tab) {
      console.log("Tab clicked:", tab);
    },

    changeDrawerFilter() {
      if (this.$vuetify.breakpoint.mobile) {
        this.drawerFilter = !this.drawerFilter;
      } else {
        this.drawerFilter = !this.drawerFilter;
      }
    },

    onClickOutside(e) {
      if (e.target.className === "v-overlay__scrim") {
        this.onUnselect();
        this.selectedFeatures = [];
      }
    },

    plantStyle() {
      return feature => {
        const geom = feature.getGeometry();
        var cnv = document.createElement("canvas");
        var ctx = cnv.getContext("2d");
        var img = new Image();
        if (feature.get("status_id") === 1 && feature.get("type_id") === 1) {
          return [
            new Style({
              image: new Icon({
                src: cutdownImg
              })
            })
          ];
        }
        if (feature.get("status_id") === 1 && feature.get("type_id") === 2) {
          return [
            new Style({
              image: new Circle({
                radius: 7,
                fill: new Fill({ color: "#67CE57" })
              })
            })
          ];
        }
        if (feature.get("status_id") === 1 && feature.get("type_id") !== 1) {
          return [
            new Style({
              fill: new Fill({ color: "#67CE57" }),
              stroke: new Stroke({
                color: "#67CE57",
                width: 3
              })
            })
          ];
        }
        if (
          feature.get("status_id") === null ||
          feature.get("status_id") !== 1
        ) {
          if (
            feature.get("type_id") === 1 &&
            (feature.get("redbook") || feature.get("pine"))
          ) {
            if (feature.get("redbook") && feature.get("sanitary_id") === 1) {
              var redBookGreen = new Style({
                image: new Icon({
                  src: redBookGreenImg
                })
              });
              return [redBookGreen];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 2) {
              var redBookOrange = new Style({
                image: new Icon({
                  src: redBookOrangeImg
                })
              });
              return [redBookOrange];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 3) {
              var redBookBlue = new Style({
                image: new Icon({
                  src: redBookBlueImg
                })
              });
              return [redBookBlue];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 4) {
              var redBookBrown = new Style({
                image: new Icon({
                  src: redBookBrownImg
                })
              });
              return [redBookBrown];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 5) {
              var redBookYellow = new Style({
                image: new Icon({
                  src: redBookYellowImg
                })
              });
              return [redBookYellow];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 6) {
              var redBookRed = new Style({
                image: new Icon({
                  src: redBookRedImg
                })
              });
              return [redBookRed];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 1) {
              var pineGreen = new Style({
                image: new Icon({
                  src: pineGreenImg
                })
              });
              return [pineGreen];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 2) {
              var pineOrange = new Style({
                image: new Icon({
                  src: pineOrangeImg
                })
              });
              return [pineOrange];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 3) {
              var pineBlue = new Style({
                image: new Icon({
                  src: pineBlueImg
                })
              });
              return [pineBlue];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 4) {
              var pineBrown = new Style({
                image: new Icon({
                  src: pineBrownImg
                })
              });
              return [pineBrown];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 5) {
              var pineYellow = new Style({
                image: new Icon({
                  src: pineYellowImg
                })
              });
              return [pineYellow];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 6) {
              var pineRed = new Style({
                image: new Icon({
                  src: pineRedImg
                })
              });
              return [pineRed];
            }
          } else {
            if (
              feature.get("type_id") === 3 ||
              feature.get("type_id") === 4 ||
              feature.get("type_id") === 5 ||
              feature.get("type_id") === 8
            ) {
              var defaultGreen = new Style({
                fill: new Fill({ color: "#67CE57" }),
                stroke: new Stroke({
                  color: "#67CE57",
                  width: 3
                })
              });
              return [defaultGreen];
            }
            if (feature.get("type_id") === 6) {
              img.src = flowerGardenImg;
              var flowerGarden = new Style({
                fill: new Fill({ color: ctx.createPattern(img, "repeat") })
              });
              return [flowerGarden];
            }
            if (feature.get("type_id") === 7) {
              img.src = lawnImg;
              var lawn = new Style({
                fill: new Fill({ color: ctx.createPattern(img, "repeat") })
              });
              return [lawn];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 1
            ) {
              var treeGreen = new Style({
                image: new Icon({
                  src: treeGreenImg
                })
              });
              return [treeGreen];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 1
            ) {
              var bushGreen = new Style({
                image: new Icon({
                  src: bushGreenImg
                })
              });
              return [bushGreen];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 2
            ) {
              var treeOrange = new Style({
                image: new Icon({
                  src: treeOrangeImg
                })
              });
              return [treeOrange];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 2
            ) {
              var bushOrange = new Style({
                image: new Icon({
                  src: bushOrangeImg
                })
              });
              return [bushOrange];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 3
            ) {
              var treeBlue = new Style({
                image: new Icon({
                  src: treeBlueImg
                })
              });
              return [treeBlue];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 3
            ) {
              var bushBlue = new Style({
                image: new Icon({
                  src: bushBlueImg
                })
              });
              return [bushBlue];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 4
            ) {
              var treeBrown = new Style({
                image: new Icon({
                  src: treeBrownImg
                })
              });
              return [treeBrown];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 4
            ) {
              var bushBrown = new Style({
                image: new Icon({
                  src: bushBrownImg
                })
              });
              return [bushBrown];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 5
            ) {
              var treeYellow = new Style({
                image: new Icon({
                  src: treeYellowImg
                })
              });
              return [treeYellow];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 5
            ) {
              var bushYellow = new Style({
                image: new Icon({
                  src: bushYellowImg
                })
              });
              return [bushYellow];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 6
            ) {
              var treeRed = new Style({
                image: new Icon({
                  src: treeRedImg
                })
              });
              return [treeRed];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 6
            ) {
              var bushRed = new Style({
                image: new Icon({
                  src: bushRedImg
                })
              });
              return [bushRed];
            }
            if (
              (feature.get("type_id") === 1 || feature.get("type_id") === 2) &&
              (feature.get("sanitary_id") === 8 ||
                feature.get("sanitary_id") === 9 ||
                feature.get("sanitary_id") === 10)
            ) {
              var darkGreen = new Style({
                image: new Circle({
                  radius: 7,
                  fill: new Fill({ color: "#67CE57" })
                })
              });
              return [darkGreen];
            }
            if (
              feature.get("type_id") === null ||
              feature.get("sanitary_id") === null ||
              feature.get("redbook") === null
            ) {
              if (geom instanceof Polygon || geom instanceof LineString) {
                var warningDefault = new Style({
                  fill: new Fill({ color: "#FF5252" }),
                  stroke: new Stroke({
                    color: "#FF5252",
                    width: 3
                  })
                });
                return [warningDefault];
              } else {
                var warning = new Style({
                  image: new Icon({
                    src: warningImg
                  })
                });
                return [warning];
              }
            }
          }
        }
      };
    },

    districtStyle() {
      return feature => {
        let baseStyle;
        baseStyle = new Style({
          fill: new Fill({
            color:
              feature.get("count") > 0
                ? String(feature.get("color"))
                : "rgba(255,255,255, 0.5)"
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 2
          }),
          text: new Text({
            text: String(feature.get("name")),
            scale: 2.3
          })
        });

        return [baseStyle];
      };
    },

    onMoveEnd() {
      this.calcViewPolygon();
    },

    async calcViewPolygon() {
      let map = this.$refs.map;
      let zoom = map.getView().getZoom();
      if (zoom > 19) {
        let size = await map.getSize();
        let extent = map.getView().calculateExtent(size);
        let sourceProj = map.getView().getProjection();
        let s = fromExtent(extent).transform(sourceProj, "EPSG:4326");
        let wkt = this.formatWKT.writeGeometry(s);
        let districts = "";
        if (this.$store.state.settings.ids_districts)
          districts = `district_id=${this.$store.state.settings.ids_districts}&`;
        let types = "";
        if (this.$store.state.settings.ids_types)
          types = `type_id=${this.$store.state.settings.ids_types}&`;
        let sanitary = "";
        if (this.$store.state.settings.ids_sanitary)
          sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
        let statuses = "";
        if (this.$store.state.settings.ids_statuses)
          statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
        let events = "";
        if (this.$store.state.settings.ids_events)
          events = `event_id=${this.$store.state.settings.ids_events}&`;
        let vermin = "";
        if (this.$store.state.settings.ids_vermin)
          vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
        let diseases = "";
        if (this.$store.state.settings.ids_diseases)
          diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
        let redbook = "";
        if (this.$store.state.settings.redbookTarget)
          redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
        // let layer = "";
        // if (this.$store.state.settings.layerTarget !== 0)
        //   layer = `layer_id=${this.$store.state.settings.layerTarget}&`;

        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = Vue.axios.CancelToken.source();
        try {
          let response;
          response = await Vue.axios.get(
            `${URL_API}plant/getByFilterForCitizen?${districts}${types}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}&geometry=${wkt}`,
            { cancelToken: this.cancelToken.token }
          );
          let data = response["data"];
          this.features = [];
          this.features = data.features;
        } catch (error) {
          console.log(error);
        }
      } else {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = Vue.axios.CancelToken.source();
        this.features = [];
      }
    },

    async getDistrictQuantity() {
      // let map = this.$refs.map;
      // let size = await map.getSize();
      // let extent = map.getView().calculateExtent(size);
      // let sourceProj = map.getView().getProjection();
      // let s = fromExtent(extent).transform(sourceProj, "EPSG:4326");
      // let wkt = this.formatWKT.writeGeometry(s);
      let districts = "";
      if (this.$store.state.settings.ids_districts)
        districts = `district_id=${this.$store.state.settings.ids_districts}`;
      // let types = "";
      // if (this.$store.state.settings.ids_types)
      //   types = `type_id=${this.$store.state.settings.ids_types}&`;
      // let sanitary = "";
      // if (this.$store.state.settings.ids_sanitary)
      //   sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
      // let statuses = "";
      // if (this.$store.state.settings.ids_statuses)
      //   statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
      // let events = "";
      // if (this.$store.state.settings.ids_events)
      //   events = `event_id=${this.$store.state.settings.ids_events}&`;
      // let vermin = "";
      // if (this.$store.state.settings.ids_vermin)
      //   vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
      // let diseases = "";
      // if (this.$store.state.settings.ids_diseases)
      //   diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
      // let redbook = "";
      // if (this.$store.state.settings.redbookTarget)
      //   redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
      // let layer = "";
      // if (this.$store.state.settings.layerTarget !== 0)
      //   layer = `layer_id=${this.$store.state.settings.layerTarget}&`;
      let response;
      response = await Vue.axios.get(
        `${URL_API}district/getbyfilter?${districts}`
      );
      let data = response["data"];
      return data;
    },

    async onSelect(feature) {
      let props;
      if (!this.passport) this.passport = true;
      props = await this.getPlantById(feature.json.properties.id);
      this.appealsByPlant = await this.getAppealsByPlant(
        feature.json.properties.id
      );
      props.geometry = feature.json.geometry;
      this.typeTarget = props.type_id;
      setTimeout(() => {
        if (JSON.stringify(this.properties) === JSON.stringify({})) {
          this.properties = props;
        } else if (feature.json.properties)
          if (feature.json.properties.id) this.properties = props;
      }, 1);
      this.geoCoordinates = Object.assign(
        {},
        feature.json.geometry.coordinates
      );
      let geometry = this.formatGeoJSON.readFeature(feature.json.geometry);
      let wktRepresenation = this.formatWKT.writeFeature(geometry);
      this.district = await this.getDistrictByArea(wktRepresenation);
    },

    onUnselect() {
      this.passport = false;
      this.drawerAppeal = false;
    },

    async setAppeal({ full_name, email, phone, body, image }) {
      this.$store.commit("updateCount");
      let properties = this.selectedFeatures[0].properties;
      await this.createAppeal({
        full_name: full_name,
        email: email,
        phone: phone,
        body: body,
        image: image,
        plant_id: properties.id,
        object_type: 1
      });
      setTimeout(() => {
        this.selectedFeatures = [];
        this.drawFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.drawerAppeal = false;
      }, 1);
    },

    openAppeal() {
      this.drawerAppeal = true;
      this.passport = false;
    },

    closeAppeal() {
      this.drawerAppeal = false;
      this.passport = true;
    }
  },

  components: {
    TreePassport,
    BushPassport,
    CurtainTreePassport,
    CurtainBushPassport,
    FlowerGardenPassport,
    LawnPassport,
    HedgePassport,
    VinesPassport,
    Filters,
    AppealPassport
  },

  created() {
    this.getDistrictsData();
    this.getBreedsData();
    this.getTypesData();
    this.getFlowersData();
    this.getVinesData();
    this.getFlowerCategoryData();
    this.getFlowerGardenData();
    this.getVerminsData();
    this.getDiseasesData();
    this.getStatusesData();
    this.getEventsData();
    this.getSanitaryData();
    this.getCategoriesData();
    this.getBushData();
  }
};
</script>

<style scoped>
::v-deep .v-snack:not(.v-snack--has-background) .v-snack__wrapper {
  background-color: #67ce57;
}

::v-deep .v-snack__content {
  color: white;
}
</style>
