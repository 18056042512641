<template>
  <v-card width="350" height="100vh">
    <v-col align="center">
      <h3>Паспорт выделенных объектов</h3>
      <v-row dense>
        <v-col cols="12" sm="9">
          <v-card-text class="text-start font-weight-bold"
            >Общее количество объектов</v-card-text
          >
        </v-col>
        <v-col cols="12" sm="3">
          <v-card-text class="text-end font-weight-bold">{{
            amount
          }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-for="el of reportCount" :key="el.id">
        <v-col cols="12" sm="9">
          <v-card-text class="text-start">{{ el.name }}</v-card-text>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card-text class="text-end">{{ el.count }}</v-card-text>
        </v-col>
      </v-row>
      <div class="mb-5">
        <v-btn
          type="button"
          class="mt-5 font-weight-bold"
          @click="selectedObjReport"
          >Сформировать отчет</v-btn
        >
      </div>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "ReportPassport",

  props: { selectedObjReport: Function, reportCount: Array },

  computed: {
    amount() {
      return this.reportCount.reduce((total, array) => total + array.count, 0);
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
