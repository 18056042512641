<template>
  <v-card style="width: 450px">
    <v-col align="center">

      <div v-if="currentState === 'passport'">
        <h3 class="mt-5 mb-2">Паспорт датчика Clarity</h3>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Координаты</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{
                Math.trunc(geoCoordinates[0] * 1000) / 1000 +
                " : " +
                Math.trunc(geoCoordinates[1] * 1000) / 1000
              }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >ID Объекта</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{ properties.code }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Дата установки датчика</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.workingStartAt" class="text-end">{{ formatDate(properties.workingStartAt) }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Дата поверки</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.testDate" class="text-end">{{ formatDate(properties.testDate) }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Показатель PM1</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.characteristics.pm1ConcMass" class="text-end">{{ properties.characteristics.pm1ConcMass.value }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Показатель PM2.5</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.characteristics.pm2_5ConcMass" class="text-end">{{ properties.characteristics.pm2_5ConcMass.value }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Показатель PM10</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.characteristics.pm1ConcMass" class="text-end">{{ properties.characteristics.pm1ConcMass.value }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Показатель NO2</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.characteristics.no2Conc" class="text-end">{{ properties.characteristics.no2Conc.value }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >Температуру воздуха (Сo)</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text v-if="properties.characteristics.temperature" class="text-end">{{ properties.characteristics.temperature.value }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="8">
            <v-card-text class="text-start"
            >Отн. влажность воздуха (%)</v-card-text>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card-text v-if="properties.characteristics.relHumid" class="text-end">{{ properties.characteristics.relHumid.value }}</v-card-text>
          </v-col>
        </v-row>

      </div>

      <div v-else>
        <h3 class="mt-5 mb-2">Статистика</h3>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Координаты</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{
                Math.trunc(geoCoordinates[0] * 1000) / 1000 +
                " : " +
                Math.trunc(geoCoordinates[1] * 1000) / 1000
              }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
            >ID Объекта</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{ properties.code }}</v-card-text>
          </v-col>
        </v-row>

        <v-row class="ma-2">
          <v-btn-toggle v-model="selectedIndicator" mandatory>
            <v-btn value="pm2_5ConcMass">PM 2.5</v-btn>
            <v-btn value="pm10ConcMass">PM 10</v-btn>
            <v-btn value="no2Conc">NO2</v-btn>
          </v-btn-toggle>
        </v-row>

        <v-row class="ma-2">
          <v-btn-toggle v-model="selectedPeriod" mandatory>
            <v-btn value="24hours">24 часа</v-btn>
            <v-btn value="week">Неделя</v-btn>
            <v-btn value="month">Месяц</v-btn>
          </v-btn-toggle>
        </v-row>

        <v-row class="mt-4" dense>
          <bar :chart-data="datacollection" :options="options" />
        </v-row>
      </div>

      <v-btn v-if="currentState === 'passport'" class="mt-4" @click="showStatistics">Статистика</v-btn>
      <v-btn v-else class="mt-4" @click="showPassport">Паспорт</v-btn>

    </v-col>
  </v-card>
</template>

<script>
import Bar from "../charts/Bar.js";
import Vue from "vue";

export default {
  name: "AirPassportClarity",
  components: {
    Bar
  },

  props: { properties: Object, geoCoordinates: Array, isDraw: Boolean },

  data() {
    return {
      currentState: 'passport',
      selectedIndicator: null,
      selectedPeriod: null,

      datacollection: {
        labels: [],
        datasets: [
          {
            label: 'мкг/м3',
            data: [],
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1
          }
        ]
      },

      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'мкг/м3'
            },
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Время'
            }
          }]
        },
        legend: {
          display: false
        }
      },

      measurementsDay: [],
      measurementsWeek: [],
      measurementsMonth: [],
    };
  },

  methods: {
    showStatistics() {
      this.currentState = 'statistics';
    },
    showPassport() {
      this.currentState = 'passport';
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    isVerificationExpired(verificationDate) {
      const oneYearLater = new Date(verificationDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      return new Date() >= oneYearLater;
    },

    toISOString(date) {
      return date.toISOString().split('.')[0] + 'Z';
    },

    formatTimeToHour(time) {
      const date = new Date(time);
      return `${this.addLeadingZero(date.getDate())}.${this.addLeadingZero(date.getMonth() + 1)} ${date.getHours()}:00`;
    },

    formatTimeToDay(time) {
      const date = new Date(time);
      return `${this.addLeadingZero(date.getDate())}.${this.addLeadingZero(date.getMonth() + 1)}`;
    },

    addLeadingZero(number) {
      return String(number).padStart(2, '0');
    },

    getDataset(type, typeTime) {
      if (typeTime === '24hours') {
        this.datacollection = {
          labels: this.measurementsDay.map(item => this.formatTimeToHour(item.time)),
          datasets: [
            {
              label: 'мкг/м3',
              data: this.measurementsDay.map(item => item[type]),
              backgroundColor: 'rgba(255, 159, 64)',
              borderColor: 'rgb(108,55,0)',
              borderWidth: 1
            }
          ]
        };
      }

      if (typeTime === 'week') {
        this.datacollection = {
          labels: this.measurementsWeek.map(item => this.formatTimeToDay(item.time)),
          datasets: [
            {
              label: 'мкг/м3',
              data: this.measurementsWeek.map(item => item[type]),
              backgroundColor: 'rgba(255, 159, 64)',
              borderColor: 'rgb(108,55,0)',
              borderWidth: 1
            }
          ]
        };
      }

      if (typeTime === 'month') {
        this.datacollection = {
          labels: this.measurementsMonth.map(item => this.formatTimeToDay(item.time)),
          datasets: [
            {
              label: 'мкг/м3',
              data: this.measurementsMonth.map(item => item[type]),
              backgroundColor: 'rgba(255, 159, 64)',
              borderColor: 'rgb(108,55,0)',
              borderWidth: 1
            }
          ]
        };
      }

    }
  },

  watch: {
    selectedIndicator: function(newValue) {
      this.getDataset(newValue, this.selectedPeriod);
    },
    selectedPeriod: function(newValue) {
      this.getDataset(this.selectedIndicator, newValue);
    },
  },

  async mounted() {
    console.log("Clarity", this.properties);
    const now = new Date();
    let startTime = this.toISOString(new Date(now.getTime() - 1 * 25 * 60 * 60 * 1000));
    const endTime = this.toISOString(now);
    let response = await Vue.axios.post(`https://proxy.eco-monitor.kz/clarity?url=https://clarity-data-api.clarity.io/v1/measurements`,
        {
          startTime: startTime,
          endTime: endTime,
          outputFrequency: "hour",
          code: this.properties.code
        }
    );

    this.measurementsDay = response.data.map(item => ({
      time: item.time,
      pm2_5ConcMass: item.characteristics.pm2_5ConcMass.value,
      pm10ConcMass: item.characteristics.pm10ConcMass.value,
      no2Conc: item.characteristics.no2Conc.value
    })).reverse();

    startTime = this.toISOString(new Date(now.getTime() - 8 * 24 * 60 * 60 * 1000));
    response = await Vue.axios.post(`https://proxy.eco-monitor.kz/clarity?url=https://clarity-data-api.clarity.io/v1/measurements`,
        {
          startTime: startTime,
          endTime: endTime,
          outputFrequency: "day",
          code: this.properties.code
        }
    );
    this.measurementsWeek = response.data.map(item => ({
      time: item.time,
      pm2_5ConcMass: item.characteristics.pm2_5ConcMass.value,
      pm10ConcMass: item.characteristics.pm10ConcMass.value,
      no2Conc: item.characteristics.no2Conc.value
    })).reverse();


    startTime = this.toISOString(new Date(now.getTime() - 31 * 24 * 60 * 60 * 1000));
    response = await Vue.axios.post(`https://proxy.eco-monitor.kz/clarity?url=https://clarity-data-api.clarity.io/v1/measurements`,
        {
          startTime: startTime,
          endTime: endTime,
          outputFrequency: "day",
          code: this.properties.code
        }
    );
    this.measurementsMonth = response.data.map(item => ({
      time: item.time,
      pm2_5ConcMass: item.characteristics.pm2_5ConcMass.value,
      pm10ConcMass: item.characteristics.pm10ConcMass.value,
      no2Conc: item.characteristics.no2Conc.value
    })).reverse();

    this.getDataset('pm2_5ConcMass', this.selectedPeriod);
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>