<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2">Паспорт контейнерной площадки</h3>

      <v-row dense>
        <v-col cols="12" sm="9">
          <v-card-text class="text-start"
          >Идентификационный номер</v-card-text
          >
        </v-col>
        <v-col cols="12" sm="3">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адрес объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="address"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Широта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ latitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Долгота</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ longitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Радиус</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="radius"
            type="number"
            step="0.01"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип контейнерной площадки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_containerplatformtype"
            v-model="container_platform_type_id"
            item-text="name"
            item-value="id"
            placeholder="Тип контейнерной площадки"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Площадь (М2)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="area"
            type="number"
            step="0.01"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Объем (М3)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="volume"
            type="number"
            step="0.01"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Количество контейнеров</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="containers_count"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Зона для КГО</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_kgozone"
            v-model="kgo_zone_id"
            item-text="name"
            item-value="id"
            placeholder="Зона для КГО"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Основание площадки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_platformbase"
            v-model="platform_base_id"
            item-text="name"
            item-value="id"
            placeholder="Основание площадки"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Принадлежность</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_belonging"
            v-model="belonging_id"
            item-text="name"
            item-value="id"
            placeholder="Принадлежность"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Ограждение</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_guardrailtype"
            v-model="guardrail_type_id"
            item-text="name"
            item-value="id"
            placeholder="Ограждение"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Расширение</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_expansion"
            v-model="expansion_id"
            item-text="name"
            item-value="id"
            placeholder="Расширение"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Для фракций ТКО</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_tkofraction"
            v-model="tko_fraction_id"
            item-text="name"
            item-value="id"
            placeholder="Для фракций ТКО"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Материал контейнера</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_containermaterial"
            v-model="container_material_id"
            item-text="name"
            item-value="id"
            placeholder="Материал контейнера"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Навес</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_canopy"
            v-model="canopy_id"
            item-text="name"
            item-value="id"
            placeholder="Навес"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Контейнеры</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_containertype"
            v-model="containertypes"
            item-text="name"
            item-value="id"
            placeholder="Контейнеры"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
            multiple
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">График вывоза</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_exportschedule"
            v-model="export_schedule_id"
            item-text="name"
            item-value="id"
            placeholder="График вывоза"
            outlined
            dense
            hide-details
            class="mb-1"
            disabled
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn
              color="warning"
              style="color: black"
              @click="$emit('open-appeal')"
          >Сообщить об ошибке</v-btn
          >
        </v-col>
      </v-row>

    </v-col>
  </v-card>
</template>

<script>

import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "MunicipalwastePassport",

  props: {
    saveMunicipalwaste: Function,
    deleteMunicipalwaste: Function,
    properties: Object,
    isDraw: Boolean,
  },

  data() {
    return {
      passport_containerplatformtype: [],
      passport_kgozone: [],
      passport_platformbase: [],
      passport_belonging: [],
      passport_guardrailtype: [],
      passport_expansion: [],
      passport_tkofraction: [],
      passport_containermaterial: [],
      passport_canopy: [],
      passport_exportschedule: [],
      passport_containertype: [],

      image: undefined,
      imageUrl: "",
      imgRules: [
        value =>
          !value ||
          value.size < 100000000 ||
          "Размер фото не должен превышать 100 МБ!"
      ],

      address: null,
      radius: null,
      container_platform_type_id: null,
      area: null,
      volume: null,
      containers_count: null,
      kgo_zone_id: null,
      platform_base_id: null,
      belonging_id: null,
      guardrail_type_id: null,
      expansion_id: null,
      tko_fraction_id: null,
      container_material_id: null,
      canopy_id: null,
      export_schedule_id: null,
      containertypes: [],
      images: [],

      latitude: null,
      longitude: null,
    };
  },

  methods: {
    saveClick() {
      const data = {
        address: this.address,
        radius: this.radius,
        container_platform_type_id: this.container_platform_type_id,
        area: this.area,
        volume: this.volume,
        containers_count: this.containers_count,
        kgo_zone_id: this.kgo_zone_id,
        platform_base_id: this.platform_base_id,
        belonging_id: this.belonging_id,
        guardrail_type_id: this.guardrail_type_id,
        expansion_id: this.expansion_id,
        tko_fraction_id: this.tko_fraction_id,
        container_material_id: this.container_material_id,
        canopy_id: this.canopy_id,
        export_schedule_id: this.export_schedule_id,
        containertypes: this.containertypes,
      };

      this.saveMunicipalwaste(data);
    },
    deleteClick() {
      this.deleteMunicipalwaste();
    },
    clearData() {
      this.address = null;
      this.radius = null;
      this.container_platform_type_id = null;
      this.area = null;
      this.volume = null;
      this.containers_count = null;
      this.kgo_zone_id = null;
      this.platform_base_id = null;
      this.belonging_id = null;
      this.guardrail_type_id = null;
      this.expansion_id = null;
      this.tko_fraction_id = null;
      this.container_material_id = null;
      this.canopy_id = null;
      this.export_schedule_id = null;
      this.containertypes = [];
      this.images = [];
    },

    uploadImage(file) {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
      reader.onerror = function(error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    clearImg() {
      if (
        confirm("Желаете удалить фото у объекта " + this.properties.id + "?")
      ) {
        this.removeImg(this.properties.id);
        this.imageUrl = "";
      } else {
        this.uploadImage();
      }
    }
  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}containerplatformtype`);
    this.passport_containerplatformtype = response.data;
    response = await Vue.axios.get(`${URL_API}kgozone`);
    this.passport_kgozone = response.data;
    response = await Vue.axios.get(`${URL_API}platformbase`);
    this.passport_platformbase = response.data;
    response = await Vue.axios.get(`${URL_API}belonging`);
    this.passport_belonging = response.data;
    response = await Vue.axios.get(`${URL_API}guardrailtype`);
    this.passport_guardrailtype = response.data;
    response = await Vue.axios.get(`${URL_API}expansion`);
    this.passport_expansion = response.data;
    response = await Vue.axios.get(`${URL_API}tkofraction`);
    this.passport_tkofraction = response.data;
    response = await Vue.axios.get(`${URL_API}containermaterial`);
    this.passport_containermaterial = response.data;
    response = await Vue.axios.get(`${URL_API}canopy`);
    this.passport_canopy = response.data;
    response = await Vue.axios.get(`${URL_API}exportschedule`);
    this.passport_exportschedule = response.data;
    response = await Vue.axios.get(`${URL_API}containertype`);
    this.passport_containertype = response.data;

    if (this.properties?.id) {
      response = await Vue.axios.get(
        `${URL_API}municipalwaste/view/${this.properties.id}`
      );
      const data = response.data;
      this.address = data.address;
      this.radius = data.radius;
      this.container_platform_type_id = data.container_platform_type_id;
      this.area = data.area;
      this.volume = data.volume;
      this.containers_count = data.containers_count;
      this.kgo_zone_id = data.kgo_zone_id;
      this.platform_base_id = data.platform_base_id;
      this.belonging_id = data.belonging_id;
      this.guardrail_type_id = data.guardrail_type_id;
      this.expansion_id = data.expansion_id;
      this.tko_fraction_id = data.tko_fraction_id;
      this.container_material_id = data.container_material_id;
      this.canopy_id = data.canopy_id;
      this.export_schedule_id = data.export_schedule_id;
      this.containertypes = data.containertypes;
      this.images = data.images;

      this.latitude = data.geometry?.coordinates[0];
      this.longitude = data.geometry?.coordinates[1];
    }
  },

};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>