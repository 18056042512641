<template>
  <div>
    <v-navigation-drawer :value="drawer" v-click-outside="onClickOutside" width="275" height="100%" bottom right fixed>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="36">mdi-filter</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            ><h3 class="text-wrap">
              Управление фильтрами
            </h3>
          </v-list-item-title>
          <v-list-item-action class="d-none d-sm-flex">
            <v-btn icon @click="$emit('close-filters')">
              <v-icon size="32">mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Районы</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredDistricts" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeDisctrict($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Объекты</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredTypes" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeType($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-item-group>
          <v-list-item style="height: 48px">
            <v-checkbox
              :value="$store.state.settings.redbookTarget"
              label="Краснокнижные"
              @change="changeRedbook"
            ></v-checkbox>
          </v-list-item>
        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Filters",

  props: { drawer: Boolean, getDistrictQuantity: Function, getClusterQuantity: Function },

  data() {
    return {
      index: 1
    };
  },

  computed: {
    filteredDistricts() {
      return this.$store.state.settings.disctricts;
    },
    filteredTypes() {
      return this.$store.state.settings.types;
    },
    filteredSanitary() {
      return this.$store.state.settings.sanitary;
    },
    filteredStatuses() {
      return this.$store.state.settings.statuses;
    },
    filteredEvents() {
      return this.$store.state.settings.events;
    },
    filteredVermin() {
      return this.$store.state.settings.vermin;
    },
    filteredDiseases() {
      return this.$store.state.settings.diseases;
    }
  },

  methods: {
    ...mapActions(["getPlantByExcel"]),
    onClickOutside(e) {
      if (e.target.className === "v-overlay__scrim") {
        if (this.drawer) this.$emit('close-filters');
      }
    },
    discardFilters() {
      this.$store.commit(
        "updateFilteredDisctricts",
        this.$store.state.districts.districtsData
      );
      this.$store.commit(
        "updateFilteredTypes",
        this.$store.state.types.typesData
      );
      this.$store.commit(
        "updateFilteredSanitary",
        this.$store.state.sanitary.sanitaryData
      );
      this.$store.commit(
        "updateFilteredStatuses",
        this.$store.state.statuses.statusesData
      );
      this.$store.commit(
        "updateFilteredEvents",
        this.$store.state.events.eventsData
      );
      this.$store.commit(
        "updateFilteredVermin",
        this.$store.state.vermins.verminsData
      );
      this.$store.commit(
        "updateFilteredDiseases",
        this.$store.state.diseases.diseasesData
      );
      this.$store.commit("setFiltredRedbook", false);
    },
    changeDisctrict(event, index) {
      this.$store.commit("setFilteredDistrict", { index, value: event });
    },
    changeType(event, index) {
      this.$store.commit("setFilteredType", { index, value: event });
    },
    changeSanitary(event, index) {
      this.$store.commit("setFilteredSanitary", { index, value: event });
    },
    changeStatus(event, index) {
      this.$store.commit("setFilteredStatus", { index, value: event });
    },
    changeEvent(event, index) {
      this.$store.commit("setFilteredEvent", { index, value: event });
    },
    changeVermin(event, index) {
      this.$store.commit("setFilteredVermin", { index, value: event });
    },
    changeDiseas(event, index) {
      this.$store.commit("setFilteredDiseas", { index, value: event });
    },
    changeRedbook() {
      this.$store.commit(
        "setFiltredRedbook",
        !this.$store.state.settings.redbookTarget
      );
    },
    downloadReport() {
      this.getPlantByExcel();
    },
    count() {
      this.getDistrictQuantity();
      setTimeout(() => this.$store.state.settings.mapChange++);
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-label {
  color: #000000de;
}
</style>
