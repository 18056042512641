<template>
  <v-container fluid class="ma-0 pa-0" style="height: 100vh;">
    <v-tabs v-if="!hideTabs" v-model="tab">
      <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
    </v-tabs>
    <MapScreen v-if="tab === 0" />
    <MapTab2 v-if="tab === 1" />
    <ImprovementTab v-if="tab === 2" />
    <MapTab3 v-if="tab === 3" />
    <MapTab5 v-if="tab === 4" />
  </v-container>
</template>

<script>
import MapScreen from './MapScreen.vue';
import MapTab2 from './MapTab2.vue';
import MapTab3 from './MapTab3.vue';
import MapTab5 from './MapTab5.vue';
import ImprovementTab from './ImprovementTab.vue';

export default {
  components: {
    MapScreen,
    MapTab2,
    MapTab3,
    MapTab5,
    ImprovementTab
  },
  
  data() {
    return {
      tab: 0,
      items: ['Зеленые насаждения', 'Водные объекты', 'Фонтаны', "Воздух", 'Отходы'],
    }
  },

  created() {
    this.setTabFromRoute();
  },

  computed: {
    hideTabs() {
      return this.$route.query.hidetab === 'true';
    }
  },

  watch: {
    '$route': function() {
      this.setTabFromRoute();
    }
  },

  methods: {
    setTabFromRoute() {
      const tabMap = {
        'green': 0,
        'water': 1,
        'fountain': 2,
        'air': 3,
        'waste': 4,
      };
      const tabParam = this.$route.query.tab;
      if (tabParam) {
        this.tab = tabMap[tabParam];
      }
    }
  }
 
}
</script>
