<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт реки</h3>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Название реки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ name }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. районы</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn
              color="warning"
              style="color: black"
              @click="$emit('open-appeal')"
          >Сообщить об ошибке</v-btn
          >
        </v-col>
      </v-row>

    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "RiverPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_serviceorganization: [],
      passport_strengtheningtype: [],

      object_id: null,
      number_: null,
      name: null,
      inflow_category: null,
      river_mouth: null,
      riverside: null,
      latitude: null,
      longitude: null,
      valves: null,
      shandors: null,
      bridges: null,
      duckers: null,
      total_length_of_drain: null,
      length_of_drainage_in_the_city: null,
      strengthening_type_id: null,
      landscaped: null,
      length_of_strengthening: null,
      number_of_tributaries: null,
      number_of_mudflow_hazardous_areas: null,
      total_length_of_tributaries: null,
      catchment_area: null,
      average_height: null,
      max_height: null,
      mouth_height: null,
      average_slope_ppm: null,
      weighted_average_slope_ppm: null,
      river_category: null,
      water_protection_strip: null,
      water_protection_zone: null,
      strengtheningtype: null,
      serviceorganizations: [],

    };
  },

  methods: {
    saveClick() {
      const data = {
        object_id: this.object_id,
        number_: this.number_,
        name: this.name,
        inflow_category: this.inflow_category,
        river_mouth: this.river_mouth,
        riverside: this.riverside,
        latitude: this.latitude,
        longitude: this.longitude,
        valves: this.valves,
        shandors: this.shandors,
        bridges: this.bridges,
        duckers: this.duckers,
        total_length_of_drain: this.total_length_of_drain,
        length_of_drainage_in_the_city: this.length_of_drainage_in_the_city,
        strengthening_type_id: this.strengthening_type_id,
        landscaped: this.landscaped,
        length_of_strengthening: this.length_of_strengthening,
        number_of_tributaries: this.number_of_tributaries,
        number_of_mudflow_hazardous_areas: this.number_of_mudflow_hazardous_areas,
        total_length_of_tributaries: this.total_length_of_tributaries,
        catchment_area: this.catchment_area,
        average_height: this.average_height,
        max_height: this.max_height,
        mouth_height: this.mouth_height,
        average_slope_ppm: this.average_slope_ppm,
        weighted_average_slope_ppm: this.weighted_average_slope_ppm,
        river_category: this.river_category,
        water_protection_strip: this.water_protection_strip,
        water_protection_zone: this.water_protection_zone,
        strengtheningtype: this.strengtheningtype,
        serviceorganizations: this.serviceorganizations
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.object_id = null;
      this.number_ = null;
      this.name = null;
      this.inflow_category = null;
      this.river_mouth = null;
      this.riverside = null;
      this.latitude = null;
      this.longitude = null;
      this.valves = null;
      this.shandors = null;
      this.bridges = null;
      this.duckers = null;
      this.total_length_of_drain = null;
      this.length_of_drainage_in_the_city = null;
      this.strengthening_type_id = null;
      this.landscaped = null;
      this.length_of_strengthening = null;
      this.number_of_tributaries = null;
      this.number_of_mudflow_hazardous_areas = null;
      this.total_length_of_tributaries = null;
      this.catchment_area = null;
      this.average_height = null;
      this.max_height = null;
      this.mouth_height = null;
      this.average_slope_ppm = null;
      this.weighted_average_slope_ppm = null;
      this.river_category = null;
      this.water_protection_strip = null;
      this.water_protection_zone = null;
      this.strengtheningtype = null;
      this.serviceorganizations = [];
    },

  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}serviceorganization`);
    this.passport_serviceorganization = response.data;
    let response2 = await Vue.axios.get(`${URL_API}strengtheningtype`);
    this.passport_strengtheningtype = response2.data;

    if (this.properties?.id) {
      response = await Vue.axios.get(
        `${URL_API}river/view/${this.properties.id}`
      );
      const data = response.data;
      this.object_id = data.object_id;
      this.number_ = data.number_;
      this.name = data.name;
      this.inflow_category = data.inflow_category;
      this.river_mouth = data.river_mouth;
      this.riverside = data.riverside;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.valves = data.valves;
      this.shandors = data.shandors;
      this.bridges = data.bridges;
      this.duckers = data.duckers;
      this.total_length_of_drain = data.total_length_of_drain;
      this.length_of_drainage_in_the_city = data.length_of_drainage_in_the_city;
      this.strengthening_type_id = data.strengthening_type_id;
      this.landscaped = data.landscaped;
      this.length_of_strengthening = data.length_of_strengthening;
      this.number_of_tributaries = data.number_of_tributaries;
      this.number_of_mudflow_hazardous_areas = data.number_of_mudflow_hazardous_areas;
      this.total_length_of_tributaries = data.total_length_of_tributaries;
      this.catchment_area = data.catchment_area;
      this.average_height = data.average_height;
      this.max_height = data.max_height;
      this.mouth_height = data.mouth_height;
      this.average_slope_ppm = data.average_slope_ppm;
      this.weighted_average_slope_ppm = data.weighted_average_slope_ppm;
      this.river_category = data.river_category;
      this.water_protection_strip = data.water_protection_strip;
      this.water_protection_zone = data.water_protection_zone;
      this.strengtheningtype = data.strengtheningtype;
      this.serviceorganizations = data.serviceorganizations;
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
