<template>
  <v-container fluid>
    <v-row dense>

      <v-col cols="12" sm="2">
        <v-select
            :items="filteredDistricts"
            v-model="targetDistrict"
            item-text="name"
            item-value="id"
            placeholder="Район"
            outlined
            dense
            hide-details
            :disabled="$store.state.user.userRole === 4 || selectionObj"
        />
      </v-col>

      <v-spacer></v-spacer>

    </v-row>


      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-x-hidden"
        max-height="87vh"
      >
        <v-row dense>
          <v-col align="center" justify="center">
            <vl-map
              data-projection="EPSG:4326"
              style="width: 100%; height: 100vh;"
              ref="map"
              @moveend="onMoveEnd"
              @pointermove="onMapPointerMove"
              :style="{ cursor: mapCursor }"
            >
              <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

              <vl-geoloc
                v-if="$store.state.settings.gps"
                @update:position="geolocPosition = $event"
              >
                <template slot-scope="geoloc">
                  <vl-feature v-if="geoloc.position" id="position-feature">
                    <vl-geom-point
                      :coordinates="geoloc.position"
                    ></vl-geom-point>
                    <vl-style-func :factory="gpsStyle" />
                  </vl-feature>
                </template>
              </vl-geoloc>

              <vl-layer-tile
                v-for="(item, index) in $store.state.settings.maps"
                :z-index="index"
                :opacity="item.alpha / 100"
                :key="item.id"
              >
                <vl-source-osm
                  v-if="(item.id === 'osm') & item.visible"
                ></vl-source-osm>
                <vl-source-xyz
                  v-if="(item.id === '2gis') & item.visible"
                  url="http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd"
                ></vl-source-xyz>
                <vl-source-bingmaps
                  v-if="(item.id === 'bingMapsAerial') & item.visible"
                  :api-key="$store.state.settings.apiKeyBing"
                  imagery-set="Aerial"
                ></vl-source-bingmaps>
                <vl-source-bingmaps
                  v-if="(item.id === 'bingMapsAerialWithLabels') & item.visible"
                  :api-key="$store.state.settings.apiKeyBing"
                  imagery-set="Aerial"
                ></vl-source-bingmaps>
                <vl-source-xyz
                  v-if="(item.id === 'mapbox') & item.visible"
                  url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGFnb3QiLCJhIjoiY2lveTNjbnRnMDBjOXZtbTJqNGNsNmk1NCJ9.bvTUlzXKG4A2ZejsF1v2Hw"
                ></vl-source-xyz>
                <vl-source-xyz
                  v-if="(item.id === 'arcgis') & item.visible"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  :tile-load-function="arcgisLoad"
                ></vl-source-xyz>
                <vl-source-xyz
                  v-if="(item.id === 'agp') & item.visible"
                  url="https://api.eco-monitor.kz/tiles/agp/{z}/{x}/{-y}.png"
                ></vl-source-xyz>
              </vl-layer-tile>

              <vl-layer-vector
                :min-resolution="15.1"
                :z-index="100"
                v-if="$store.state.user.userRole !== 3"
                :key="layerDistrictUpdate"
              >
                <vl-source-vector :loader="getDistrictsData" />
                <vl-style-func :factory="districtStyle" />
              </vl-layer-vector>

              <vl-layer-vector id="layerTaxator" :z-index="100" v-else>
                <vl-source-vector
                  :loader="getCurrentTaxatorGrid"
                  ref="taxatorSource"
                />
              </vl-layer-vector>

              <vl-layer-vector id="layerMunicipalwaste" :z-index="101">
                <vl-source-vector
                  :features.sync="featuresMunicipalwaste"
                  ident="featuresMunicipalwaste"
                >
                  <vl-style-func :factory="municipalwasteFeaturesStyle" />
                </vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                  v-if="isDraw"
                  :type="typeTarget.geometry_type"
                  source="featuresDraw"
                  @drawend="drawEndMun"
                  stop-click
              />
              <vl-interaction-select
                :features.sync="targetMunicipalwaste"
                :layers="['layerMunicipalwaste']"
                ident="featuresMunicipalwasteSelected"
                @select="onMunicipalwasteSelect"
                @unselect="onMunicipalwasteUnselect"
                ref="selectMunicipalwaste"
              />
              <vl-interaction-modify
                source="featuresMunicipalwasteSelected"
                @modifyend="onModifyEnd"
              />

              <vl-interaction-select
                v-if="$store.state.user.userRole !== 3 && showComment"
                :features.sync="selectedFeaturesComment"
                :layers="['layerComment']"
                @select="onSelectComment"
                ident="featuresCommentSelected"
                ref="selectComment"
              />
              <vl-layer-vector id="layerDrawComment" :z-index="998">
                <vl-source-vector
                  :features.sync="drawFeaturesComment"
                  ident="featuresCommentDraw"
                ></vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="isComment"
                type="Point"
                source="featuresCommentDraw"
                @drawend="drawEndComment"
                stop-click
              />

              <vl-layer-vector id="layerAPI" :z-index="101">
                <vl-source-vector
                  :features.sync="features"
                  ident="featuresAPI"
                  ref="vectorSource"
                />
                <PlantStyle />
              </vl-layer-vector>

              <vl-layer-vector id="layerDraw" :z-index="102">
                <vl-source-vector
                  :features.sync="drawFeatures"
                  ident="featuresDraw"
                ></vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="typeTarget.id && isDraw"
                :type="typeTarget.geometry_type"
                source="featuresDraw"
                stop-click
              />
              <vl-interaction-select
                :features.sync="selectedFeatures"
                :layers="['layerAPI', 'layerDraw', 'layerGroup']"
                @select="onSelect"
                @unselect="onUnselect"
                ident="featuresSelected"
                ref="select"
              />
              <vl-interaction-translate
                source="featuresSelected"
                @translateend="translateEndGroup"
              />

              <vl-layer-vector id="layerGroup" :z-index="103">
                <vl-source-vector
                  :features.sync="drawGroupFeatures"
                  ident="groupDraw"
                ></vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="selectedGroup"
                type="Polygon"
                source="groupDraw"
                @drawstart="drawStartGroup"
                @drawend="drawEndGroup"
                stop-click
              />

              <vl-layer-vector id="layerReportDraw" :z-index="104">
                <vl-source-vector
                  :features.sync="drawReportFeatures"
                  ident="reportDraw"
                ></vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="selectionObj"
                type="Polygon"
                source="reportDraw"
                @drawstart="drawStartReport"
                @drawend="drawEndReport"
                stop-click
              />
              <vl-interaction-select
                :features.sync="selectedReportFeatures"
                :layers="['layerReportDraw']"
                ident="featuresReportSelected"
                @select="onReportSelect"
                @unselect="onReportUnselect"
                ref="selectReport"
              />

              <vl-layer-vector id="layerLength" :z-index="105">
                <vl-source-vector
                  :features.sync="distanceFeatures"
                  ident="lengthDraw"
                />
                <vl-style-func :factory="measureFeaturesStyle" />
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="distance"
                type="LineString"
                source="lengthDraw"
                @drawstart="drawStartLength"
                @drawend="drawEndLength"
                stop-click
              >
                <vl-style-func :factory="measureDrawStyle" />
              </vl-interaction-draw>

              <vl-layer-vector id="layerArea" :z-index="106">
                <vl-source-vector
                  :features.sync="areaFeatures"
                  ident="areaDraw"
                />
                <vl-style-func :factory="measureFeaturesStyle" />
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="area"
                type="Polygon"
                source="areaDraw"
                @drawstart="drawStartLength"
                @drawend="drawEndLength"
                stop-click
              >
                <vl-style-func :factory="measureDrawStyle" />
              </vl-interaction-draw>

              <vl-overlay
                :position="breedPosition"
                positioning="center-left"
                :offset="[15, 0]"
              >
                <div class="ol-tooltip">
                  {{ breedName }}
                </div>
              </vl-overlay>
            </vl-map>
          </v-col>

          <MunicipalwastePassport
              v-if="targetMunicipalwaste.length > 0 && drawerAppeal === false"
              :saveMunicipalwaste="saveMunicipalwaste"
              :deleteMunicipalwaste="deleteMunicipalwaste"
              :properties="propertiesMunicipalwaste"
              :isDraw="isDraw"
              @open-appeal="openAppeal"
          />

          <TreePassport
            v-if="typeTarget.id === 1 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <BushPassport
            v-if="typeTarget.id === 2 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <CurtainTreePassport
            v-if="typeTarget.id === 3 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <CurtainBushPassport
            v-if="typeTarget.id === 4 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <HedgePassport
            v-if="typeTarget.id === 5 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <FlowerGardenPassport
            v-if="typeTarget.id === 6 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <LawnPassport
            v-if="typeTarget.id === 7 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <VinesPassport
            v-if="typeTarget.id === 8 && passport"
            :setPlants="setPlants"
            :properties="properties"
            :isDraw="isDraw"
            :district="district"
            @remove-plant="removePlants"
          />
          <ReportPassport
            v-if="selectionObj && reportPassport"
            :selectedObjReport="selectedObjReport"
            :reportCount="reportCount"
          />
        </v-row>
      </v-sheet>
      <Filters
        v-show="drawerFilter"
        @close-filters="drawerFilter = !drawerFilter"
        :updateDistricts="updateDistricts"
        :updateClusters="updateClusters"
        @filter-message="filterMsg"
      />

      <AppealPassport
          :drawer="drawerAppeal"
          :setAppeal="setAppeal"
          @close-appeal="closeAppeal"
      />

      <v-card>
        <v-snackbar v-model="snackbar" :timeout="timeout" top right outlined>
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";
import { fromExtent } from "ol/geom/Polygon";
import * as olExt from "vuelayers/dist/ol-ext";
import {
  Fill,
  Stroke,
  Style,
  Circle as CircleStyle,
  Icon,
  Text
} from "ol/style";
import { fromLonLat } from "ol/proj";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import TileState from "ol/TileState";

import TreePassport from "../passports/TreePassport.vue";
import BushPassport from "../passports/BushPassport.vue";
import CurtainTreePassport from "../passports/CurtainTreePassport.vue";
import CurtainBushPassport from "../passports/CurtainBushPassport.vue";
import FlowerGardenPassport from "../passports/FlowerGardenPassport.vue";
import LawnPassport from "../passports/LawnPassport.vue";
import HedgePassport from "../passports/HedgePassport.vue";
import VinesPassport from "../passports/VinesPassport.vue";
import ReportPassport from "../passports/ReportPassport.vue";
import Filters from "@/components/tools/Filters.vue";
import { URL_API } from "../settings/apiConnect";
import PlantStyle from "@/components/PlantStyle.vue";
import markerImg from "@/assets/marker.png";
import { getLength, getArea } from "ol/sphere";
import MunicipalwastePassport from "@/passports/MunicipalwastePassport.vue";
import wasteImg from "@/assets/waste.png";
import AppealPassport from "@/passports/AppealPassport.vue";

export default {
  name: "MapTab",

  data() {
    return {
      zoom: 13,
      center: [76.889709, 43.238949],

      geolocPosition: undefined,
      formatWKT: new WKT(),
      formatGeoJSON: new GeoJSON(),
      typeTarget: { id: 0, name: "РЕДАКТИРОВАНИЕ", geometry_type: "Point" },
      features: [],
      featuresMunicipalwaste: [],
      targetMunicipalwaste: [],
      propertiesMunicipalwaste: {},
      drawFeatures: [],
      drawFeaturesComment: [],
      selectedFeatures: [],
      selectedFeaturesComment: [],
      drawGroupFeatures: [],
      drawReportFeatures: [],
      selectedReportFeatures: [],
      distanceFeatures: [],
      areaFeatures: [],
      geometries: {},
      reportGeometry: null,
      reportCount: [],
      taxatorgrid: [],
      passport: false,
      reportPassport: false,
      properties: {},
      propertiesComment: {},
      geoCoordinates: {},
      district: {},
      cancelToken: null,
      snackbar: false,
      text: "",
      timeout: 2000,
      isComment: false,
      isDraw: false,
      editObj: false,
      selectedGroup: false,
      selectionObj: false,
      distance: false,
      area: false,
      drawerFilter: false,
      searchID: null,
      breedPosition: undefined,
      breedName: undefined,
      curBreedId: undefined,
      lstBreedId: undefined,
      mapCursor: "default",
      //helpTooltipElement: null,
      //helpTooltip: null,
      tooltipHide: "",
      sketch: null,
      listener: null,
      layerDistrictUpdate: 0,
      layerClusterUpdate: 10000,
      startCopy: false,

      targetDistrict: 2,
      drawerAppeal: false
    };
  },

  computed: {
    ...mapGetters(["allLayersData"]),
    filteredDistricts() {
      let arr = [];
      this.$store.state.settings.disctricts.forEach(el => {
        arr.push(el);
      });
      return arr;
    },
    layerTarget: {
      get() {
        return this.$store.state.settings.layerTarget;
      },
      set(value) {
        this.$store.commit("updateLayerTarget", value);
      }
    },
    types() {
      return this.$store.state.types.typesData;
    },
    layers() {
      let arr = [];
      if (
        this.$store.state.user.userRole === 4 ||
        this.$store.state.user.userRole === 5
      ) {
        this.allLayersData.filter(el => {
          if (el.id === 0) arr.push(el);
        });
        return arr;
      } else {
        return this.allLayersData;
      }
    },
    showComment: {
      get() {
        return !this.$store.state.settings.commentHide;
      },
      set(value) {
        this.$store.commit("setFiltredComment", value);
      }
    }
  },

  watch: {
    layerTarget: function() {
      this.onMoveEnd();
      this.layerDistrictUpdate++;
      this.layerClusterUpdate++;
    },
    targetDistrict(newValue, oldValue) {
      console.log('targetDistrict changed from', oldValue, 'to', newValue);
      this.getMunicipalwaste(newValue);
    }
  },

  methods: {
    ...mapActions([
      "getCurrentTaxatorGrid",
      "getPlantById",
      "createPlantData",
      "pushPlantData",
      "destroyPlantData",
      "getDistrictByArea",
      "pushPlantGeometries",
      "createComment",
      "pushComment",
      "destroyComment",
      "getDistrictsData",
      "getClusterGrid",
      "createAppeal",
      "getAppealsByPlant"
    ]),

    onModifyEnd(evt) {
      console.log('Modified feature:', evt.json);
      const feature = evt.json[0];
      this.geometries.push({ id: feature.properties.id, geometry: this.formatWKT.writeFeature(this.formatGeoJSON.readFeature(evt.json[0].geometry)) });
    },

    async saveMunicipalwaste(data) {
      const lastSelect = this.targetMunicipalwaste[this.targetMunicipalwaste.length - 1];
      if(lastSelect.properties) {
        // Существующий объект
        console.log(lastSelect.properties.id);
        console.log(data);
        let formData = new FormData();
        formData.append('id', lastSelect.properties.id);

        for (const [key, value] of Object.entries(data)) {
          if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                formData.append(`${key}[${index}]`, item);
              });
            } else {
              formData.append(key, value);
            }
          }
        }
        console.log(formData);

        if (this.geometries.length > 0) {
          formData.append('geometry', this.geometries[this.geometries.length-1].geometry);
        }

        await Vue.axios.post(`${URL_API}municipalwaste/update`, formData);
      } else {
        // Новый объект
        const geometry = this.formatGeoJSON.readFeature(lastSelect.geometry);
        const wkt = this.formatWKT.writeFeature(geometry);

        console.log(data);
        let formData = new FormData();
        formData.append('geometry', wkt);

        for (const [key, value] of Object.entries(data)) {
          if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                formData.append(`${key}[${index}]`, item);
              });
            } else {
              formData.append(key, value);
            }
          }
        }
        console.log(formData);

        await Vue.axios.post(`${URL_API}municipalwaste/store`, formData);
      }
      this.geometries = [];
      this.getMunicipalwaste(this.targetDistrict);
      this.targetMunicipalwaste = [];
    },

    async deleteMunicipalwaste() {
      const lastSelect = this.targetMunicipalwaste[this.targetMunicipalwaste.length - 1];
      if (lastSelect.properties) {
        let next = confirm("Вы действительно хотите удалить объект?");
        if (next) {
          let formData = new FormData();
          formData.append('id', lastSelect.properties.id);

          await Vue.axios.post(`${URL_API}municipalwaste/destroy`, formData);

          this.getMunicipalwaste(this.targetDistrict);
          this.targetMunicipalwaste = [];
        }
      }
    },

    drawEndMun() {
      console.log(this.drawFeatures);
      this.targetMunicipalwaste.push(
          this.drawFeatures[this.drawFeatures.length - 1]
      );
    },

    municipalwasteFeaturesStyle() {
      return () => {
        return new Style({
          image: new Icon({
            src: wasteImg
          })
        });
      };
    },

    districtStyle() {
      return () => {
        let baseStyle = new Style({
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 2
          }),
        });
        return [baseStyle];
      };
    },
    styleFunction() {
      return feature => {
        let baseStyle = new Style({
          fill: new Fill({
            color: String(feature.get("color"))
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 1
          }),
          text: new Text({
            text: feature.get("name") ? String(feature.get("name")) : "",
            scale: 1.5
          })
        });
        return [baseStyle];
      };
    },

    arcgisLoad(imageTile, src) {
      let map = this.$refs.map;
      let zoom = map.getView().getZoom();
      if (zoom > 19) {
        imageTile.setState(TileState.ERROR);
      } else {
        imageTile.getImage().src = src;
      }
    },

    measureFeaturesStyle() {
      return () => {
        let baseStyle = new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)"
          }),
          stroke: new Stroke({
            color: "#ffcc33",
            width: 2
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: "#ffcc33"
            })
          })
        });
        return [baseStyle];
      };
    },

    measureDrawStyle() {
      return () => {
        let baseStyle = new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)"
          }),
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.5)",
            lineDash: [10, 10],
            width: 2
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.7)"
            }),
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)"
            })
          })
        });
        return [baseStyle];
      };
    },

    gpsStyle() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: markerImg
            })
          })
        ];
      };
    },

    onMoveEnd() {
      /*      this.selectedFeatures = [];
      this.calcViewPolygon();*/
    },

    async calcViewPolygon() {
      let map = this.$refs.map;
      let zoom = map.getView().getZoom();
      if (zoom > 18) {
        let size = await map.getSize();
        let extent = map.getView().calculateExtent(size);
        let sourceProj = map.getView().getProjection();
        let s = fromExtent(extent).transform(sourceProj, "EPSG:4326");
        let wkt = this.formatWKT.writeGeometry(s);
        let districts = "";
        if (this.$store.state.settings.ids_districts)
          districts = `district_id=${this.$store.state.settings.ids_districts}&`;
        let types = "";
        if (this.$store.state.settings.ids_types)
          types = `type_id=${this.$store.state.settings.ids_types}&`;
        let breeds = "";
        if (this.$store.state.settings.ids_breeds)
          breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
        let sanitary = "";
        if (this.$store.state.settings.ids_sanitary)
          sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
        let statuses = "";
        if (this.$store.state.settings.ids_statuses)
          statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
        let events = "";
        if (this.$store.state.settings.ids_events)
          events = `event_id=${this.$store.state.settings.ids_events}&`;
        let vermin = "";
        if (this.$store.state.settings.ids_vermin)
          vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
        let diseases = "";
        if (this.$store.state.settings.ids_diseases)
          diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
        let redbook = "";
        if (this.$store.state.settings.redbookTarget)
          redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
        let layer = "";
        if (this.$store.state.settings.layerTarget !== 0)
          layer = `layer_id=${this.$store.state.settings.layerTarget}&`;

        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = Vue.axios.CancelToken.source();
        try {
          let response;
          if (this.$store.state.user.userRole === 5) {
            response = await Vue.axios.get(
              `${URL_API}plant/getbyfilter?district_id=${this.$store.state.user.currentUser.district_id}&${types}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}&geometry=${wkt}`,
              { cancelToken: this.cancelToken.token }
            );
          } else {
            response = await Vue.axios.get(
              `${URL_API}plant/getbyfilter?${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}&geometry=${wkt}`,
              { cancelToken: this.cancelToken.token }
            );
          }

          let data = response["data"];
          this.features = [];
          this.features = data.features;

          setTimeout(() => {
            if (this.searchID !== undefined) {
              const id = this.searchID;
              const source = this.$refs.vectorSource.$source;
              source.forEachFeature(feature => {
                if (feature.get("id") == id) {
                  let feature1 = olExt.writeGeoJsonFeature(feature);
                  this.selectedFeatures.push(feature1);
                  this.editObj = true;
                  this.searchID = null;
                  return;
                }
              });
            }
          }, 2000);
        } catch (error) {
          console.log(error);
        }
      } else if (zoom >= 14.9 && zoom <= 18) {
        let size = await map.getSize();
        let extent = map.getView().calculateExtent(size);
        let sourceProj = map.getView().getProjection();
        let s = fromExtent(extent).transform(sourceProj, "EPSG:4326");
        let wkt = this.formatWKT.writeGeometry(s);
        let districts = "";
        if (this.$store.state.settings.ids_districts)
          districts = `district_id=${this.$store.state.settings.ids_districts}&`;
        let types = "";
        if (this.$store.state.settings.ids_types)
          types = `type_id=${this.$store.state.settings.ids_types}&`;
        let breeds = "";
        if (this.$store.state.settings.ids_breeds)
          breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
        let sanitary = "";
        if (this.$store.state.settings.ids_sanitary)
          sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
        let statuses = "";
        if (this.$store.state.settings.ids_statuses)
          statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
        let events = "";
        if (this.$store.state.settings.ids_events)
          events = `event_id=${this.$store.state.settings.ids_events}&`;
        let vermin = "";
        if (this.$store.state.settings.ids_vermin)
          vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
        let diseases = "";
        if (this.$store.state.settings.ids_diseases)
          diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
        let redbook = "";
        if (this.$store.state.settings.redbookTarget)
          redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
        let layer = "";
        if (this.$store.state.settings.layerTarget !== 0)
          layer = `layer_id=${this.$store.state.settings.layerTarget}&`;

        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = Vue.axios.CancelToken.source();

        try {
          if (
            types.indexOf("1") > 0 ||
            types.indexOf("2") > 0 ||
            types.indexOf("6") > 0
          ) {
            return;
          }
          if (this.$store.state.settings.ids_types === "") {
            types = `type_id=3,4,5,7,8&`;
          }
          let response;
          if (this.$store.state.user.userRole === 5) {
            response = await Vue.axios.get(
              `${URL_API}plant/getbyfilter?district_id=${this.$store.state.user.currentUser.district_id}&${types}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}&geometry=${wkt}`,
              { cancelToken: this.cancelToken.token }
            );
          } else {
            response = await Vue.axios.get(
              `${URL_API}plant/getbyfilter?${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}&geometry=${wkt}`,
              { cancelToken: this.cancelToken.token }
            );
          }

          let data = response["data"];
          this.features = [];
          this.features = data.features;

          setTimeout(() => {
            if (this.searchID !== undefined) {
              const id = this.searchID;
              const source = this.$refs.vectorSource.$source;
              source.forEachFeature(feature => {
                if (feature.get("id") == id) {
                  let feature1 = olExt.writeGeoJsonFeature(feature);
                  this.selectedFeatures.push(feature1);
                  this.editObj = true;
                  this.searchID = null;
                  return;
                }
              });
            }
          }, 2000);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.features = [];
      }
    },

    async updateDistricts() {
      this.layerDistrictUpdate++;
    },

    async updateClusters() {
      this.layerClusterUpdate++;
    },

    async getDistrictQuantity() {
      let districts = "";
      if (this.$store.state.settings.ids_districts)
        districts = `district_id=${this.$store.state.settings.ids_districts}&`;
      let types = "";
      if (this.$store.state.settings.ids_types)
        types = `type_id=${this.$store.state.settings.ids_types}&`;
      let breeds = "";
      if (this.$store.state.settings.ids_breeds)
        breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
      let sanitary = "";
      if (this.$store.state.settings.ids_sanitary)
        sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
      let statuses = "";
      if (this.$store.state.settings.ids_statuses)
        statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
      let events = "";
      if (this.$store.state.settings.ids_events)
        events = `event_id=${this.$store.state.settings.ids_events}&`;
      let vermin = "";
      if (this.$store.state.settings.ids_vermin)
        vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
      let diseases = "";
      if (this.$store.state.settings.ids_diseases)
        diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
      let redbook = "";
      if (this.$store.state.settings.redbookTarget)
        redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
      let layer = "";
      if (this.$store.state.settings.layerTarget !== 0)
        layer = `layer_id=${this.$store.state.settings.layerTarget}&`;
      let response;
      response = await Vue.axios.get(
        `${URL_API}district/getbyfilter?${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}`
      );
      let data = response["data"];
      return data;
    },

    async getClusterQuantity() {
      let districts = "";
      if (this.$store.state.settings.ids_districts)
        districts = `district_id=${this.$store.state.settings.ids_districts}&`;
      let types = "";
      if (this.$store.state.settings.ids_types)
        types = `type_id=${this.$store.state.settings.ids_types}&`;
      let breeds = "";
      if (this.$store.state.settings.ids_breeds)
        breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
      let sanitary = "";
      if (this.$store.state.settings.ids_sanitary)
        sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
      let statuses = "";
      if (this.$store.state.settings.ids_statuses)
        statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
      let events = "";
      if (this.$store.state.settings.ids_events)
        events = `event_id=${this.$store.state.settings.ids_events}&`;
      let vermin = "";
      if (this.$store.state.settings.ids_vermin)
        vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
      let diseases = "";
      if (this.$store.state.settings.ids_diseases)
        diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
      let redbook = "";
      if (this.$store.state.settings.redbookTarget)
        redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
      let layer = "";
      if (this.$store.state.settings.layerTarget !== 0)
        layer = `layer_id=${this.$store.state.settings.layerTarget}&`;
      let response;
      response = await Vue.axios.get(
        `${URL_API}clustergrid/getbyfilter?${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}`
      );
      let data = response["data"];
      return data;
    },

    drawStartGroup() {
      this.selectedFeatures = [];
      this.drawGroupFeatures = [];
    },

    drawEndGroup(e) {
      const geometry = e.feature.getGeometry();
      const extent = e.feature.getGeometry().getExtent();
      const source = this.$refs.vectorSource.$source;
      source.forEachFeatureIntersectingExtent(extent, feature => {
        const coordinate = feature.getGeometry().getCoordinates();
        const type = feature.getGeometry().getType();
        if (type === "Point") {
          if (geometry.intersectsCoordinate(coordinate)) {
            feature = olExt.writeGeoJsonFeature(feature);
            this.selectedFeatures.push(feature);
          }
        } else if (type === "Polygon") {
          for (let el of coordinate[0]) {
            if (geometry.intersectsCoordinate(el)) {
              feature = olExt.writeGeoJsonFeature(feature);
              this.selectedFeatures.push(feature);
              return;
            }
          }
        } else if (type === "LineString") {
          for (let el of coordinate) {
            if (geometry.intersectsCoordinate(el)) {
              feature = olExt.writeGeoJsonFeature(feature);
              this.selectedFeatures.push(feature);
              return;
            }
          }
        }
      });
      this.drawGroupFeatures = [];
    },

    translateEndGroup(value) {
      this.drawGroupFeatures = [];
      this.geometries = { plants: [] };
      value.json.forEach(fe => {
        let geometry = this.formatGeoJSON.readFeature(fe.geometry);
        let wkt = this.formatWKT.writeFeature(geometry);
        this.geometries.plants.push({ id: fe.properties.id, geometry: wkt });
      });
    },

    async copyGroup() {
      if (!this.startCopy) {
        this.startCopy = true;
        this.selectedGroup = !this.selectedGroup;
        if (this.selectedFeatures.length > 1) {
          let clone = this.selectedFeatures.map(obj => ({ ...obj }));
          this.selectedFeatures = [];
          clone.forEach(element => {
            this.drawFeatures.push(element);
          });
          this.selectedFeatures = this.drawFeatures;
        }
      } else {
        let next = confirm("Вы хотите отменить копирование группы?");
        if (next) {
          this.startCopy = false;
          this.drawFeatures = [];
          this.selectedFeatures = [];
          this.onMoveEnd();
        }
      }
    },

    async saveGroup() {
      this.selectedGroup = !this.selectedGroup;
      if (this.selectedFeatures.length > 1) {
        if (this.drawFeatures.length > 1) {
          let nextCopy = confirm(
            "Вы действительно хотите сохранить копию группы?"
          );
          if (nextCopy) {
            while (this.selectedFeatures.length !== 0) {
              let properties = await this.getPlantById(
                this.selectedFeatures[0].properties.id
              );
              this.typeTarget = this.types.find(e => {
                return e.id === properties.type_id;
              });
              await this.setPlants(properties);
              this.selectedFeatures.shift();
              this.geometries.plants.shift();
            }
            this.selectedGroup = false;
            this.startCopy = false;
            this.drawFeatures = [];
            this.onMoveEnd();
            return;
          } else {
            return;
          }
        }

        let next = confirm(
          "Вы действительно хотите сохранить перемещение группы?"
        );
        if (next) {
          await this.pushPlantGeometries(this.geometries);
          this.selectedFeatures = [];
          this.calcViewPolygon();
          this.selectedGroup = false;
        }
      }
      this.selectedFeatures = [];
    },

    async deleteGroup() {
      let next = confirm("Вы действительно хотите удалить группу?");
      if (next) {
        while (this.selectedFeatures.length !== 0) {
          this.removePlants();
          this.selectedFeatures.shift();
        }
        this.geometries = { plants: [] };
        this.onMoveEnd();
      }
    },

    async setComment({ comment }) {
      let feature = this.formatGeoJSON.readFeature(
        this.selectedFeaturesComment[0]
      );
      let wktRepresenation = this.formatWKT.writeFeature(feature);
      let properties = this.selectedFeaturesComment[0].properties;
      if (properties) {
        await this.pushComment({
          id: properties.id,
          geometry: wktRepresenation,
          comment: comment,
          user_id: this.$store.state.user.currentUser.id
        });
      } else {
        await this.createComment({
          geometry: wktRepresenation,
          comment: comment,
          user_id: this.$store.state.user.currentUser.id
        });
      }
      this.showComment = true;
      setTimeout(() => {
        this.drawFeaturesComment = [];
        this.selectedFeaturesComment = [];
        this.showComment = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
      }, 1);
    },

    async removeComment() {
      let properties = this.selectedFeaturesComment[0].properties;
      if (!properties) {
        this.drawFeaturesComment = this.drawFeaturesComment.filter(
          item =>
            !(
              JSON.stringify(item.geometry) ===
              JSON.stringify(this.selectedFeaturesComment[0].geometry)
            )
        );
        return;
      } else {
        await this.destroyComment(properties.id);
      }
      this.showComment = true;
      setTimeout(() => {
        this.drawFeaturesComment = [];
        this.selectedFeaturesComment = [];
        this.showComment = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
      }, 1);
    },

    async setPlants({
      age,
      height,
      diameter,
      serial_number,
      sanitary_id,
      category_id,
      status_id,
      in_charge,
      planting_date,
      registry_date,
      quantity,
      length,
      area,
      vermin,
      diseases,
      events,
      breeds,
      lawn_name,
      lawn_type,
      bushes,
      vines,
      flower_garden_id,
      flowers,
      image
    }) {
      let feature;
      let wktRepresenation;
      if (this.geometries.plants.length > 0) {
        wktRepresenation = this.geometries.plants[0].geometry;
      } else {
        feature = this.formatGeoJSON.readFeature(this.selectedFeatures[0]);
        wktRepresenation = this.formatWKT.writeFeature(feature);
      }

      if (this.startCopy) {
        this.district = await this.getDistrictByArea(wktRepresenation);
        this.createPlantData({
          geometry: wktRepresenation,
          layer_id: this.layerTarget,
          age: age,
          height: height,
          diameter: diameter,
          serial_number: serial_number,
          sanitary_id: sanitary_id,
          category_id: category_id,
          status_id: status_id,
          district_id: this.district.id,
          in_charge: in_charge,
          planting_date: planting_date,
          registry_date: registry_date,
          quantity: quantity,
          length: length,
          area: area,
          vermin: vermin,
          diseases: diseases,
          events: events,
          type_id: this.typeTarget.id,
          breeds: breeds && breeds[0] ? [breeds[0].id] : undefined,
          lawn_name: lawn_name,
          lawn_type: lawn_type,
          bushes: bushes,
          vines: vines,
          flower_garden_id: flower_garden_id,
          flowers: flowers,
          image: image
        });
        if (this.selectedFeatures.length === 1) {
          setTimeout(() => {
            this.text = this.$store.state.message.msg;
            this.snackbar = true;
            this.calcViewPolygon();
          }, 2000);
        }
        return;
      }

      let properties = this.selectedFeatures[0].properties;
      if (properties) {
        this.pushPlantData({
          id: properties.id,
          geometry: wktRepresenation,
          layer_id: this.layerTarget,
          age: age,
          height: height,
          diameter: diameter,
          serial_number: serial_number,
          sanitary_id: sanitary_id,
          category_id: category_id,
          status_id: status_id,
          district_id: this.district.id,
          in_charge: in_charge,
          planting_date: planting_date,
          registry_date: registry_date,
          quantity: quantity,
          length: length,
          area: area,
          vermin: vermin,
          diseases: diseases,
          events: events,
          type_id: this.typeTarget.id,
          breeds: breeds,
          lawn_name: lawn_name,
          lawn_type: lawn_type,
          bushes: bushes,
          vines: vines,
          flower_garden_id: flower_garden_id,
          flowers: flowers,
          image: image
        });
      } else {
        this.drawFeatures.forEach(e => {
          feature = this.formatGeoJSON.readFeature(e);
          wktRepresenation = this.formatWKT.writeFeature(feature);
          this.createPlantData({
            geometry: wktRepresenation,
            layer_id: this.layerTarget,
            age: age,
            height: height,
            diameter: diameter,
            serial_number: serial_number,
            sanitary_id: sanitary_id,
            category_id: category_id,
            status_id: status_id,
            district_id: this.district.id,
            in_charge: in_charge,
            planting_date: planting_date,
            registry_date: registry_date,
            quantity: quantity,
            length: length,
            area: area,
            vermin: vermin,
            diseases: diseases,
            events: events,
            type_id: this.typeTarget.id,
            breeds: breeds,
            lawn_name: lawn_name,
            lawn_type: lawn_type,
            bushes: bushes,
            vines: vines,
            flower_garden_id: flower_garden_id,
            flowers: flowers,
            image: image
          });
        });
      }
      this.selectedFeatures = [];
      this.drawFeatures = [];
      this.passport = false;
      setTimeout(() => {
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.calcViewPolygon();
      }, 2000);
    },

    async removePlants() {
      let properties = this.selectedFeatures[0].properties;
      if (!properties) {
        this.drawFeatures = this.drawFeatures.filter(
          item =>
            !(
              JSON.stringify(item.geometry) ===
              JSON.stringify(this.selectedFeatures[0].geometry)
            )
        );
        return;
      } else {
        await this.destroyPlantData(properties.id);
      }
      setTimeout(() => {
        this.selectedFeatures = [];
        this.drawFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.calcViewPolygon();
      }, 3000);
    },

    drawEndComment() {
      this.selectedFeaturesComment = [];
      if (this.drawFeaturesComment.length > 1) this.drawFeaturesComment.shift();
      this.selectedFeaturesComment.push(this.drawFeaturesComment[0]);
    },

    drawEnd(evt) {
      this.selectedFeatures = [];

      if (this.$store.state.user.userRole === 3) {
        var drawGeom = evt.feature.getGeometry();
        var sourceProj = this.$refs.map.getView().getProjection();
        const source = this.$refs.taxatorSource.$source;

        if (drawGeom instanceof Polygon) {
          var polygon = drawGeom.clone().transform(sourceProj, "EPSG:4326");
          var polygonCoord = polygon.getLinearRing(0).getCoordinates();
          for (var i = 0, ii = polygonCoord.length; i < ii; ++i) {
            var p1 = polygonCoord[i];
            var pgCoord = fromLonLat([p1[0], p1[1]]);
            source.forEachFeature(feature => {
              var taxatorGeom = feature.getGeometry();
              if (!taxatorGeom.intersectsCoordinate(pgCoord)) {
                alert("за пределами таксатор грид");
                this.drawFeatures = [];
                this.selectedFeatures = [];
                return;
              } else {
                this.selectedFeatures.push(
                  this.drawFeatures[this.drawFeatures.length - 1]
                );
              }
            });
          }
        } else if (drawGeom instanceof LineString) {
          var line = drawGeom.clone().transform(sourceProj, "EPSG:4326");
          var lineCoord = line.getCoordinates();
          for (var j = 0, jj = lineCoord.length; j < jj; ++j) {
            var l1 = lineCoord[j];
            var lCoord = fromLonLat([l1[0], l1[1]]);
            source.forEachFeature(feature => {
              var taxatorGeom = feature.getGeometry();
              if (!taxatorGeom.intersectsCoordinate(lCoord)) {
                alert("за пределами таксатор грид");
                this.drawFeatures = [];
                this.selectedFeatures = [];
                return;
              } else {
                this.selectedFeatures.push(
                  this.drawFeatures[this.drawFeatures.length - 1]
                );
              }
            });
          }
        } else {
          var point = drawGeom.clone().transform(sourceProj, "EPSG:4326");
          var pointCoord = point.getCoordinates();
          var pCoord = fromLonLat([pointCoord[0], pointCoord[1]]);
          source.forEachFeature(feature => {
            var taxatorGeom = feature.getGeometry();
            if (!taxatorGeom.intersectsCoordinate(pCoord)) {
              alert("за пределами таксатор грид");
              this.drawFeatures = [];
              this.selectedFeatures = [];
              return;
            } else {
              this.selectedFeatures.push(
                this.drawFeatures[this.drawFeatures.length - 1]
              );
            }
          });
        }
      } else {
        this.selectedFeatures.push(
          this.drawFeatures[this.drawFeatures.length - 1]
        );
      }
    },

    async onSelect(feature) {
      if (this.isDraw || this.editObj) {
        let props;
        if (!this.passport) this.passport = true;
        if (!feature.json.properties) {
          props = { type_id: this.typeTarget.id };
        } else {
          props = await this.getPlantById(feature.json.properties.id);
        }
        props.geometry = feature.json.geometry;
        this.typeTarget = this.types.find(e => {
          return e.id === props.type_id;
        });
        setTimeout(() => {
          if (JSON.stringify(this.properties) === JSON.stringify({})) {
            this.properties = props;
          } else if (feature.json.properties)
            if (feature.json.properties.id) this.properties = props;
        }, 1);
        this.geoCoordinates = Object.assign(
          {},
          feature.json.geometry.coordinates
        );
        let geometry = this.formatGeoJSON.readFeature(feature.json.geometry);
        let wktRepresenation = this.formatWKT.writeFeature(geometry);
        this.district = await this.getDistrictByArea(wktRepresenation);
      }
    },

    onUnselect(feature) {
      if (feature.json.properties) {
        this.properties = {};
        this.geoCoordinates = {};
        this.geometries = { plants: [] };
        this.passport = false;
      }
    },

    async onSelectComment(feature) {
      console.log(feature.json.properties);
      this.propertiesComment = Object.assign(feature.json.properties, {
        geometry: feature.json.geometry
      });
    },

    async onReportSelect(feature) {
      this.passport = false;
      this.selectedFeatures = [];
      let geometry = this.formatGeoJSON.readFeature(feature.json.geometry);
      let wktRepresenation = this.formatWKT.writeFeature(geometry);
      this.reportGeometry = wktRepresenation;
      let response;

      let districts = "";
      if (this.$store.state.settings.ids_districts)
        districts = `district_id=${this.$store.state.settings.ids_districts}&`;
      let types = "";
      if (this.$store.state.settings.ids_types)
        types = `type_id=${this.$store.state.settings.ids_types}&`;
      let breeds = "";
      if (this.$store.state.settings.ids_breeds)
        breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
      let sanitary = "";
      if (this.$store.state.settings.ids_sanitary)
        sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
      let statuses = "";
      if (this.$store.state.settings.ids_statuses)
        statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
      let events = "";
      if (this.$store.state.settings.ids_events)
        events = `event_id=${this.$store.state.settings.ids_events}&`;
      let vermin = "";
      if (this.$store.state.settings.ids_vermin)
        vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
      let diseases = "";
      if (this.$store.state.settings.ids_diseases)
        diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
      let redbook = "";
      if (this.$store.state.settings.redbookTarget)
        redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
      let layer = "";
      if (this.layerTarget !== 0)
        layer = `&layer_id=${this.$store.state.settings.layerTarget}&`;

      response = await Vue.axios.get(
        `${URL_API}type/getbyfilter?${layer}${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}geometry=${this.reportGeometry}`
      );
      this.reportCount = response.data;
      this.reportPassport = true;
    },

    drawEndReport() {
      this.selectedReportFeatures = [];
      this.selectedReportFeatures.push(
        this.drawReportFeatures[this.drawReportFeatures.length - 1]
      );
    },

    drawStartReport() {
      this.selectedReportFeatures = [];
      this.drawReportFeatures = [];
    },

    onReportUnselect() {
      this.reportPassport = false;
    },

    async selectedObjReport() {
      let amount = this.reportCount.reduce(
        (total, array) => total + array.count,
        0
      );
      if (amount > 5000000) {
        this.text = amount + " объектов слишком много для формирования отчета";
        this.snackbar = true;
      } else {
        let isContinue = confirm(
          `Для отчёта выбрано ${amount} объектов. Время формирования отчёта ~${amount /
            300000} минут. Продолжить?`
        );
        if (!isContinue) return;
        let districts = "";
        if (this.$store.state.settings.ids_districts)
          districts = `district_id=${this.$store.state.settings.ids_districts}&`;
        let types = "";
        if (this.$store.state.settings.ids_types)
          types = `type_id=${this.$store.state.settings.ids_types}&`;
        let breeds = "";
        if (this.$store.state.settings.ids_breeds)
          breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
        let sanitary = "";
        if (this.$store.state.settings.ids_sanitary)
          sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
        let statuses = "";
        if (this.$store.state.settings.ids_statuses)
          statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
        let events = "";
        if (this.$store.state.settings.ids_events)
          events = `event_id=${this.$store.state.settings.ids_events}&`;
        let vermin = "";
        if (this.$store.state.settings.ids_vermin)
          vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
        let diseases = "";
        if (this.$store.state.settings.ids_diseases)
          diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
        let redbook = "";
        if (this.$store.state.settings.redbookTarget)
          redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
        let layer = "";
        if (this.layerTarget !== 0)
          layer = `&layer_id=${this.$store.state.settings.layerTarget}&`;
        await Vue.axios({
          url: `${URL_API}plant/exportExcel?${layer}${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}geometry=${this.reportGeometry}`,
          method: "GET",
          responseType: "blob"
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
        });
        this.reportPassport = false;
        this.selectionObj = false;
        this.selectedReportFeatures = [];
        this.drawReportFeatures = [];
      }
    },

    measureDistance() {
      this.distance = !this.distance;
      this.distanceFeatures = [];
    },

    measureArea() {
      this.area = !this.area;
      this.areaFeatures = [];
    },

    async onMapPointerMove(evt) {
      if (evt.dragging) {
        return;
      }
      if (
        this.isDraw === false &&
        this.editObj === false &&
        this.selectedGroup === false &&
        this.selectionObj === false &&
        this.distance === false &&
        this.area === false
      ) {
        let map = this.$refs.map;
        let zoom = map.getView().getZoom();
        if (zoom > 18) {
          var features = [];
          await this.$refs.map.forEachFeatureAtPixel(evt.pixel, function(
            feature
          ) {
            features.push(feature);
          });
          if (features.length > 0) {
            this.mapCursor = "pointer";
            var f1 = features[0];
            var curType = f1.get("type_id");
            this.curBreedId = f1.get("id");
            var data;
            var res;
            this.breedPosition = evt.coordinate;

            if (this.curBreedId !== this.lstBreedId) {
              this.lstBreedId = this.curBreedId;

              switch (curType) {
                case 1:
                  res = await Vue.axios.get(
                    `${URL_API}breed/getByPlantId?id=${this.curBreedId}`
                  );
                  break;
                case 2:
                  res = await Vue.axios.get(
                    `${URL_API}bush/getByPlantId?id=${this.curBreedId}`
                  );
                  break;
                default:
                  return;
              }

              data = res.data;
              if (data.length > 0) {
                this.breedName = data[0].name;
              }
              this.curBreedId = undefined;
              //this.tooltipHide = "";
            }
          } else {
            //this.tooltipHide = "d-hide";
            this.mapCursor = "default";
            this.breedPosition = this.breedName = this.curBreedId = this.lstBreedId = undefined;
          }
        } else {
          this.mapCursor = "default";
          this.breedPosition = this.breedName = this.curBreedId = this.lstBreedId = undefined;
        }
      } else if (this.distance === true || this.area === true) {
        //this.tooltipHide = "d-hide";
      } else {
        //this.tooltipHide = "d-hide";
      }
    },

    selectionTool() {
      this.selectionObj = !this.selectionObj;
      this.selectedReportFeatures = [];
      this.drawReportFeatures = [];
    },

    onComment() {
      this.isComment = !this.isComment;
      if (!this.isComment) {
        this.drawFeaturesComment = [];
        this.selectedFeaturesComment = [];
      }
    },

    onCreate() {
      this.isDraw = !this.isDraw;
      if (!this.isDraw) {
        this.drawFeatures = [];
        this.selectedFeatures = [];
        this.passport = false;
      }
    },

    onEdit() {
      if (this.selectedFeatures.length > 1) {
        let next = confirm("Вы хотите отменить копирование группы?");
        if (!next) {
          return;
        }
      }
      this.editObj = !this.editObj;
      if (this.editObj) {
        this.$refs.select.setActive(true);
        this.$refs.selectReport.setActive(true);
        this.$refs.selectMunicipalwaste.setActive(true);
      } else {
        this.$refs.select.setActive(false);
        this.$refs.selectReport.setActive(false);
        this.$refs.selectMunicipalwaste.setActive(false);
        this.selectedFeatures = [];
      }
    },

    searchObjectData(searchID) {
      this.searchID = searchID;
    },

    searchObjectMsg() {
      this.text = this.$store.state.message.msg;
      this.snackbar = true;
    },

    filterMsg() {
      this.text = this.$store.state.message.msg;
      this.snackbar = true;
    },

    formatLength(line) {
      let length = getLength(line);
      let output;
      if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + " " + "км";
      } else {
        output = Math.round(length * 100) / 100 + " " + "м";
      }
      return output;
    },

    formatArea(polygon) {
      let area = getArea(polygon);
      let output;
      if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + " " + "км2";
      } else {
        output = Math.round(area * 100) / 100 + " " + "м2";
      }
      return output;
    },

    drawStartLength(evt) {
      this.breedPosition = evt.json.geometry.coordinates[0];
      this.sketch = evt.feature;

      let tooltipCoord = evt.coordinate;

      this.listener = this.sketch.getGeometry().on("change", evt => {
        let geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        /*measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);*/
        this.breedName = output;
        this.tooltipHide = "";
        console.log(tooltipCoord);
      });
    },

    drawEndLength(evt) {
      console.log(evt);
    },

    onMunicipalwasteSelect() {
      console.log(this.targetMunicipalwaste);
      this.propertiesMunicipalwaste = this.targetMunicipalwaste[0].properties;
    },

    onMunicipalwasteUnselect() {
      this.propertiesMunicipalwaste = {};
      this.drawerAppeal = false;
    },

    async getMunicipalwaste(districtId) {
      let response = await Vue.axios.get(`${URL_API}municipalwaste/getbyfilter?district_id=${districtId}`);
      this.featuresMunicipalwaste = response.data.features;
    },

    async setAppeal({ full_name, email, phone, body, image }) {
      this.$store.commit("updateCount");
      let properties = this.targetMunicipalwaste[0].properties;
      await this.createAppeal({
        full_name: full_name,
        email: email,
        phone: phone,
        body: body,
        image: image,
        plant_id: properties.id,
        object_type: 4
      });
      setTimeout(() => {
        this.targetMunicipalwaste = [];
        this.drawFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.drawerAppeal = false;
      }, 1);
    },

    openAppeal() {
      this.drawerAppeal = true;
    },

    closeAppeal() {
      this.drawerAppeal = false;
    }
  },

  async mounted() {
    this.$store.state.settings.map = this.$refs.map;
    this.$refs.select.setActive(false);
    this.$refs.selectReport.setActive(false);
    this.$refs.selectMunicipalwaste.setActive(true);

    /*let response = await Vue.axios.get(`${URL_API}taxatorgrid/getcurrent`);
    this.taxatorgrid = response["data"];*/

    this.getMunicipalwaste(this.targetDistrict);
  },

  components: {
    AppealPassport,
    MunicipalwastePassport,
    TreePassport,
    BushPassport,
    CurtainTreePassport,
    CurtainBushPassport,
    FlowerGardenPassport,
    LawnPassport,
    HedgePassport,
    VinesPassport,
    ReportPassport,
    Filters,
    PlantStyle,
  }
};
</script>

<style scoped>
.ol-attribution {
  display: none;
}

::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
}

::v-deep .v-list-item__action {
  margin: 0;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}
</style>
